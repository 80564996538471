<ion-content>
  <div class="redirect">
    <p>
      연결에 실패했습니다.
      <br>다시 시도해주세요.
    </p>
    <ion-button (click)="reload()">
      다시 시도
    </ion-button>
  </div>
</ion-content>