<ion-header translucent>
  <ion-toolbar>
    <ion-title>배달주소</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismissModal()">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="search-modal-container">
  <form class="search-form">
    <ion-item lines="none">
      <ion-input #Search [formControl]="addressForm" placeholder="도로명주소, 건물명 또는 지번입력" (keyup.enter)="search()"></ion-input>
      <ion-icon *ngIf="addressForm.value.length > 0" slot="end" class="init-button" name="close-outline" (click)="initForm()"></ion-icon>
      <ion-icon slot="end" class="search-button" name="search-outline" (click)="search()" ></ion-icon>
    </ion-item>
  </form>
  <div class="validation">
    <p class="" *ngIf="addressForm.invalid">{{ addressForm.errors['reason'] }}</p>
  </div>
  
  <ion-list *ngIf="documents" class="search-result">
    <ion-item *ngFor="let document of documents">
      <ion-label (click)="pickAddress(document.road_address_name, document.address_name)">
        <h2>{{ document.place_name }}</h2>
        <h3>{{ document.address_name }}</h3>
        <p><span class="road">도로명</span>{{ document.road_address_name }}</p>
      </ion-label>
    </ion-item>

    <ion-item *ngIf="documents && documents.length === 0" lines="none">
      <ion-label>
        <h2>검색된 결과가 없습니다.</h2>
      </ion-label>
    </ion-item>
  </ion-list>

  <div class="pagination" *ngIf="(documents && documents.length > 0) && (pagination.totalCount > pagination.size)">
    <ion-item lines="none">
      <ion-icon *ngIf="pagination.pageNumber > 1" slot="start" name="arrow-back-circle-outline" (click)="paginate('prev')"></ion-icon>
      <p>{{ pagination.pageNumber }} / {{ pagination.pageLength }}</p>
      <ion-icon *ngIf="pagination.pageNumber < pagination.pageLength" slot="end" name="arrow-forward-circle-outline" (click)="paginate('next')"></ion-icon>
    </ion-item>
  </div>
</ion-content>