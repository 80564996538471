/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Subscription } from 'rxjs';
import { NavController } from '@ionic/angular';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { trimOrganization } from '../../core/2/util';
import { SiteService } from '../../core/3/site.service';

interface HomeViewModel {
  _id: string;
  siteName: string;
  sortKey: number;
  thumbnail: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  homeViewModel: HomeViewModel[] = [];

  private siteSubscription: Subscription;

  constructor(
    private siteService: SiteService,
    private navController: NavController
  ) { }

  ngOnInit() {
    this.observeSite();
  }

  ngOnDestroy() {
    if (this.siteSubscription) {
      this.siteSubscription.unsubscribe();
      this.siteSubscription = undefined;
    }
  }

  observeSite() {
    const imageUrlPrefix = 'https://ssproxy.ucloudbiz.olleh.com/v1/AUTH_d722d13e-44ea-44ad-8c9b-2f5763ce3d40/ghostkitchen/site/';

    this.siteService.latestSubject.subscribe(siteDocs => {
      this.homeViewModel = Object.values(siteDocs).map(siteDoc => {
          return {
            _id: siteDoc._id,
            siteName: trimOrganization(siteDoc.siteName),
            sortKey: siteDoc.sortKey,
            thumbnail: `${imageUrlPrefix}${siteDoc._id}-front.jpg`
          };
        })
        .filter(homeViewModel => homeViewModel.sortKey > 0)
        .sort((a, b) => (b.sortKey - a.sortKey));
    });
  }

  goSite(id: string) {
    this.navController.navigateRoot(`/${id}`);
  }
}
