/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
  UrlTree,
} from '@angular/router';

import { UtilService } from '../../core/1/util.service';
import { LogService } from '../../core/3/log.service';
import { UnifiedOrderService } from '../../core/4/unified-order.service';

@Injectable({
  providedIn: 'root'
})
export class ReceiptGuardService implements CanActivate {
  constructor(
    private router: Router,
    private unifiedOrderService: UnifiedOrderService,
    private utilService: UtilService,
    private logService: LogService
  ) { }

  promiseForInit(orderNo: string) {
    const parsedUrl = this.router.parseUrl('/my-order');

    return new Promise<boolean | UrlTree>((resolve) => {
      if (this.unifiedOrderService.unifiedOrderForUserTel) {
        const order = this.unifiedOrderService.unifiedOrderForUserTel.find(doc => doc.orderNo === orderNo);

        if (order) {
          resolve(true);
        } else {
          this.utilService.toastError('주문정보를 찾는데 실패했습니다.');
          this.logService.error(`ReceiptGuardService:: orderNo: ${orderNo} 해당하는 주문정보를 찾지 못했습니다.`);
          resolve(parsedUrl);
        }
      } else {
        resolve(parsedUrl);
      }
    });
  }

  canActivate(route: ActivatedRouteSnapshot) {
    const orderNo = route.paramMap.get('order');
    return this.promiseForInit(orderNo);
  }
}
