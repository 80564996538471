<ion-app>
  <ion-menu side="end" type="overlay" contentId="main">
    <ion-header>
      <ion-toolbar class="menu-toolbar">
        <ion-img class="menu-logo" src="assets/images/app_menu_logo.svg"></ion-img>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list class="site-nav" *ngIf="appViewModel">
        <a *ngFor="let item of appViewModel"
        [class.active]="currentSite === item.siteKey"
        (click)="goToSite(item.siteKey)">
          <ion-item>
          {{ item.siteName }}
          </ion-item>
        </a>
      </ion-list>
    </ion-content>
    <ion-footer class="menu-footer">
      <a href="http://ghostkitchen.net/" target="_blank">고스트키친?</a>
    </ion-footer>
  </ion-menu>
  <ion-router-outlet id="main"></ion-router-outlet>
  <div *ngIf="currentSite
    && currentSite !== 'my-order' && currentSite !== 'map'
    && currentSiteClosedState && currentSiteClosedState.isClosed" class="closed-message-footer">
    <h3>{{ currentSiteClosedState.msgClosed }}</h3>
  </div>
</ion-app>
