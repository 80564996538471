<ion-header translucent>
  <ion-toolbar>
    <app-back-button [defaultHref]="currentSite"></app-back-button>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen class="shop-container" *ngIf="shopViewModel" #IonContent>
  <div class="shop-header-box">
    <div class="shop-background" *ngIf="shopViewModel.menus[0]"
      [style.background-image]="'url(' + shopViewModel.menus[0].foods[0].imageUrl+ ')'">
    </div>
    <div class="shop-header">
      <div class="room-label">
        <span><b>{{ shopViewModel.roomNo }}</b>호</span>
      </div>
      <h3 class="shop-name">{{ shopViewModel.shopName }}</h3>
    </div>
  </div>
  
  <ion-list class="menu-list" lines="none">
    <ion-item-group *ngFor="let menu of shopViewModel.menus; let menuIdx = index">
      <ion-item class="menu-group">
        <ion-label>{{menu.foodGrpName}}</ion-label>
      </ion-item>

      <a *ngFor="let food of menu.foods; let foodIdx = index"
        #foodRef
        [class.auto-scroll-target]="food.foodNo === foodNo"
        (click)="food.foodSoldOut || !payable ? undefined : goToMenuForm(menuIdx + '-' + foodIdx)"
        routerLinkActive="active">
        <ion-item class="menu-item">
          <ion-avatar slot="end">
            <img *ngIf="food.imageUrl" [src]="food.imageUrl"
                onerror="this.src='assets/images/logo_placeholder.png';">
          </ion-avatar>
          <ion-label [class.soldout]="food.foodSoldOut">
            <b *ngIf="food.foodSoldOut">품절</b>
            <h2>{{ food.foodName }}</h2>
            <h3>{{ food.foodOptGroups[0].foodOpts[0].optPrice | number }}원</h3>
            <p>{{ food.foodCont }}</p>
          </ion-label>
        </ion-item>
      </a>
    </ion-item-group>
  </ion-list>
</ion-content>

<ion-footer>
  <ng-container *ngIf="payable; else baemin">
    <ion-button class="order" expand="block" *ngIf="totalQty > 0" (click)="goOrder()">
      <b>{{ totalAmount | number }}원</b>주문하기
      <span class="total">
        <ion-icon name="cart-outline"></ion-icon>
        <span class="badge">{{ totalQty }}</span>
      </span>
    </ion-button>
  </ng-container>
  <ng-template #baemin>
    <ion-button class="button-baemin" color="baemin" expand="block" (click)="goBaemin()">
      <b>배민으로 주문하기</b>
    </ion-button>
  </ng-template>
</ion-footer>
