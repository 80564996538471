<ion-header>
  <ion-toolbar>
    <ion-img slot="start" class="toolbar-logo" src="assets/images/app_toolbar_logo.svg"></ion-img>
    <ion-title>
      고스트키친 {{ siteName }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<!-- 쇼핑카트 -->
<ion-content fullscreen class="site-container" #scrollArea>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button (click)="goShoppingCart()" *ngIf="productsInCart > 0">
      <span class="total">
        <ion-icon name="cart-outline"></ion-icon>
        <span class="badge">{{ productsInCart }}</span>
      </span>
    </ion-fab-button>
    <ion-fab-button color="tertiary" size="small" (click)="scrollToTop(700)" class="scroll-top-fab">
      <span>
        <ion-icon name="arrow-up-outline"></ion-icon>
      </span>
    </ion-fab-button>
  </ion-fab>

  <div class="site-header">
    <div class="site-title">
      <h1>{{ siteName }}</h1>
      <button (click)="openDestMap()">
        <ion-icon name="map-outline"></ion-icon>
        <span>지도 보기</span>
      </button>
    </div>
    <button *ngIf="payable" class="my-order" (click)="goToMyOrder()">
      <div class="inner-box">
        <span>주문내역</span>
      </div>
    </button>
    <ion-segment [value]="viewOption" mode="md" color="ghostkitchen" (ionChange)="segmentChanged($event)">
      <ion-segment-button value="1">
        <ion-label>업소 보기</ion-label>
      </ion-segment-button>
      <ion-segment-button value="2">
        <ion-label>모든 업소 메뉴 보기</ion-label>
      </ion-segment-button>
    </ion-segment>
  </div>

  <div class="divider"></div>

  <div class="site-content">
    <app-grid-shop [site]="siteDoc._id" [showUp]="viewOption === '1'" (scrollToTop)="scrollToTop()"></app-grid-shop>
    <app-grid-menu [site]="siteDoc._id" [showUp]="viewOption === '2'"></app-grid-menu>
  </div>

  <footer>
    <p>(주) 단추로끓인수프 | 대표이사 : 최정이
    <br>고객센터 : <a href="tel:1522-6385">1522-6385</a>
    <br>서울특별시 서초구 서운로 142-4, 8층
    <br>사업자 등록번호 : 672-81-00712
    <br>통신판매업신고 : 2020-서울서초-1111
    </p>
    <a href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=6728100712" target="_blank">사업자정보 확인</a>
  </footer>
</ion-content>