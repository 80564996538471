<div class="order-detail-container">
  <div class="pattern"></div>
  <h1>{{ siteName }}</h1>
  <!-- <button *ngIf="order.site !== 'gk-lounge'" class="site-map-button" (click)="goSiteMap(roomNo)">
    <ion-icon name="navigate-outline"></ion-icon>
    <span>위치보기</span>
  </button> -->
  <div class="shop-item">
    <p class="shop-name">{{ roomNo | number }}호 {{ order.shopName }}</p>
    <div *ngFor="let food of order.foods" class="menu-item">
      <p class="food-name">{{ food.foodName + (food.foodOpts[0].optName ? ('_' + food.foodOpts[0].optName) : '') }}<span *ngIf="food.foodQty"> x {{ food.foodQty }}</span></p>
      <p class="food-price">{{ food.foodOpts[0].optPrice * food.foodOpts[0].optQty * food.foodQty | number }}원</p>
      <p *ngFor="let option of food.foodOpts.slice(1)" class="food-option">+ {{ option.optName }} ({{ option.optPrice * option.optQty * food.foodQty | number }})</p>
    </div>
  </div>
  <div class="bill-item">
    <p>총 주문금액</p>
    <p class="order-amount">{{ order.orderAmount | number }}원</p>
  </div>
  <div class="additional-item">
    <p><b>주문일시:</b> {{ order.orderDate | date:'yyyy-MM-dd HH:mm:ss' }}</p>
    <p *ngIf="order.orderMsg"><b>요청사항:</b> {{ order.orderMsg }}</p>
    <p><b>주문방법:</b> {{ orderProcess }}</p>
    <p *ngIf="order.orderNo" class="order-no"><b>주문번호:</b> {{ order.orderNo }}</p>
  </div>
  <div class="pattern bottom"></div>
</div>