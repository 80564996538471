/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { UnifiedOrderFood } from '../../schema/1/schema-common';
import { UnifiedMenuFood } from '../../schema/3/schema';
import { UnifiedMenuFoodOptGrpUI, UnifiedMenuFoodOptUI, UnifiedMenuFoodUI } from '../../schema/4/schema-ui';

import { UnifiedMenuService } from '../../core/1/unified-menu.service';

import { ShoppingCartService } from '../../pages/shopping-cart/shopping-cart.service';

@Component({
  selector: 'app-menu-form',
  templateUrl: './menu-form.component.html',
  styleUrls: ['./menu-form.component.scss']
})
export class MenuFormComponent implements OnInit, OnDestroy {
  currentSite: string;
  shopNo: string;

  foodOrdPrice = 0;
  foodQty = 1;
  food: UnifiedMenuFood;

  subscription: Subscription;
  unifiedOrderFoods: UnifiedOrderFood[] = [];

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private shoppingCartService: ShoppingCartService,
    private unifiedMenuService: UnifiedMenuService
  ) { }

  ngOnInit() {
    this.initialize();
    this.observeShoppingCart();
    this.initControltype();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  initialize() {
    this.currentSite = this.route.snapshot.paramMap.get('site');
    const shopNo = this.route.snapshot.paramMap.get('shop');

    const indexes = this.route.snapshot.paramMap.get('food').split('-');
    // Service에 있는 imageURL을 변경하는 일이 없도록 깊은 복사를 한다.
    const unifiedMenu = this.unifiedMenuService.unifiedMenuForSite;
    const shop = unifiedMenu.find(menu => menu.shopNo === shopNo);
    const food = cloneDeep(shop.menus[Number(indexes[0])].foods[Number(indexes[1])]);
    if (food.imageUrl) {
      food.imageUrl = this.getUcloudImageUrl(food.imageUrl);
    }

    this.shopNo = shopNo;
    this.food = food;
  }

  getUcloudImageUrl(imageUrl: string) {
    const ktStaticPrefix = 'https://ssproxy.ucloudbiz.olleh.com/v1/AUTH_d722d13e-44ea-44ad-8c9b-2f5763ce3d40/ghostkitchen/toe-menu-images';

    const partialUrl = imageUrl.split('.com');
    partialUrl[0] = ktStaticPrefix;
    imageUrl = partialUrl.join('');
    return imageUrl;
  }

  private observeShoppingCart() {
    this.subscription = this.shoppingCartService.latestUnifiedOrderFoodsSubject.subscribe(unifiedOrderFoods => {
      this.unifiedOrderFoods = unifiedOrderFoods;
      this.foodOrdPrice = this.unifiedOrderFoods.reduce((sum, food) => sum + food.foodOrdPrice, 0);
    });
  }

  changeFoodQty(action: 'increase' | 'decrease') {
    if (action === 'increase') {
      ++this.foodQty;
    } else if (this.foodQty > 1) {
      --this.foodQty;
    }
  }

  /*******************************************************************
   * face에서 copy한 내용
   *******************************************************************/
  sum() {
    const food: UnifiedMenuFoodUI = this.food;

    let sum = 0;
    for (const foodOptGroup of food.foodOptGroups) {
      for (const foodOpt of foodOptGroup.foodOpts as UnifiedMenuFoodOptUI[]) {
        if (foodOpt._optQty > 0) {
          sum += foodOpt._optQty * foodOpt.optPrice;
        }
      }
    }

    return sum * this.foodQty;
  }

  initControltype() {
    this.food.foodOptGroups.forEach((optGroup: UnifiedMenuFoodOptGrpUI) => {
      // 모두 다 선택해야 하는 경우
      // 1. 디폴트 옵션, 보통 1개
      if (optGroup.optGrpMinSel === optGroup.foodOpts.length) {
        this.initRadio(optGroup);
        optGroup._controlType = 'radio';
        // 2. 필수 선택 옵션, 보통 1개
      } else if (optGroup.optGrpMinSel === 1 && optGroup.optGrpMaxSel === 1 && optGroup.foodOpts.length > 1) {
        this.initRadio(optGroup);
        optGroup._controlType = 'radio';
      } else {
        optGroup._controlType = 'checkbox';
      }
    });
  }

  disabled(foodOptGroupIndex: number, foodOptIndex: number) {
    const optGroup = this.food.foodOptGroups[foodOptGroupIndex];
    const foodOpt: UnifiedMenuFoodOptUI = optGroup.foodOpts[foodOptIndex];

    // 최대 선택에 제한이 있는 경우라면 (밥투정: 고기덕후세트/공기밥추가선택)
    if (optGroup.optGrpMaxSel < optGroup.foodOpts.length) {
      const numChecked = optGroup.foodOpts.reduce((acc, $foodOpt: UnifiedMenuFoodOptUI) => acc + ($foodOpt._optQty > 0 ? 1 : 0), 0);

      // 최대 허용치에 도달했다면
      if (optGroup.optGrpMaxSel === numChecked) {
        return foodOpt._optQty > 0 ? false : true;
      } else {
        return false;
      }
    }
  }

  checked(foodOptGroupIndex: number, foodOptIndex: number) {
    const optGroup = this.food.foodOptGroups[foodOptGroupIndex];
    const foodOpt: UnifiedMenuFoodOptUI = optGroup.foodOpts[foodOptIndex];

    //
    // UI 코드에서 모델을 초기화하고 있다. 별로 좋은 방법은 아니지만 여기서는 효율적이다.
    if (foodOpt._optQty === undefined) {
      if (optGroup.optGrpMinSel === optGroup.foodOpts.length) {
        foodOpt._optQty = 1;
        return true;
      }

      if (optGroup.optGrpMinSel > 0 && foodOptIndex < optGroup.optGrpMinSel) {
        foodOpt._optQty = 1;
        return true;
      }
    } else {
      return (foodOpt._optQty > 0) ? true : false;
    }
  }

  onCheckboxChange(foodOptGroupIndex: number, foodOptIndex: number, $event: any) {
    // debugLog($event.target.checked);

    const optGroup = this.food.foodOptGroups[foodOptGroupIndex];
    const foodOpt: UnifiedMenuFoodOptUI = optGroup.foodOpts[foodOptIndex];

    foodOpt._optQty = $event.target.checked ? 1 : 0;
  }
  /*******************************************************************
   * end of copy
   *******************************************************************/

  onRadioChange(foodOptGroupIndex: number, $event: any) {
    // debugLog($event.target.value);

    const optGroup: UnifiedMenuFoodOptGrpUI = this.food.foodOptGroups[foodOptGroupIndex];
    const foodOpt: UnifiedMenuFoodOptUI = optGroup.foodOpts[$event.target.value];

    // radio인 경우에는 그룹에서 1개만 선택할 수 있으므로 나머지는 0
    for (const opt of optGroup.foodOpts as UnifiedMenuFoodOptUI[]) {
      opt._optQty = 0;
    }

    foodOpt._optQty = 1;
  }

  initRadio(optGroup: UnifiedMenuFoodOptGrpUI) {
    // radio인 경우에는 그룹에서 1개만 선택할 수 있으므로 나머지는 0
    for (const foodOpt of optGroup.foodOpts as UnifiedMenuFoodOptUI[]) {
      foodOpt._optQty = 0;
    }
    optGroup.foodOpts[0]._optQty = 1;
  }

  radioValue(foodOptGroupIndex: number) {
    const optGroup = this.food.foodOptGroups[foodOptGroupIndex];
    const foodOpts: UnifiedMenuFoodOptUI[] = optGroup.foodOpts;
    let value = 0;

    foodOpts.forEach((foodOpt, idx) => {
      if (foodOpt._optQty > 0) {
        value = idx;
      }
    });

    return value;
  }

  addFood(food: UnifiedMenuFood) {
    this.shoppingCartService.addFood(food, this.shopNo, this.foodQty, () => this.location.back());
  }
}
