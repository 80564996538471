/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, Input } from '@angular/core';

// Ionic의 백버튼이 중복해서 눌리는 것을 방지하기 위한 커스텀 백버튼
// https://github.com/ionic-team/ionic-framework/issues/18455
@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html'
})
export class BackButtonComponent {
  @Input() defaultHref: string;
  public disableBack = false;

  constructor() {}

  blockBack() {
    this.disableBack = true;
  }
}
