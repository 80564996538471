/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, OnInit, Input } from '@angular/core';

import { UnifiedOrder } from '../../schema/3/schema';

import { unifiedOrderChannelMappings, unifiedOrderDeliveryTypeMappings } from '../../core/1/string-map';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {
  @Input() order: UnifiedOrder;
  @Input() siteName: string;
  roomNo: string;
  orderProcess = '';

  constructor() { }

  ngOnInit() {
    this.roomNo = this.order.room.split('-')[2];
    this.orderProcess = unifiedOrderChannelMappings[this.order.orderChannel] ?? '';
    const deliveryType = unifiedOrderDeliveryTypeMappings[this.order.deliveryType];
    this.orderProcess += deliveryType ? `/${deliveryType}` : '';
  }
}
