<ion-header>
  <ion-toolbar>
    <app-back-button [defaultHref]="''"></app-back-button>
    <ion-title>주문상세</ion-title>
    <button *ngIf="order.site !== 'gk-lounge'" class="site-map-button" (click)="goSiteMap(roomNo)">
      <span>위치보기</span>
    </button>
  </ion-toolbar>
</ion-header>


<ion-content *ngIf="order" fullscreen>
  <div class="receipt-content">
    <app-order-detail [order]="order" [siteName]="siteName"></app-order-detail>
  </div>

  <button class="confirm" (click)="goBack()">확인</button>
</ion-content>