<ion-header>
  <ion-toolbar>
    <ion-title>{{ siteName }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <div class="map-container">
    <div class="center pin-board" [class.fade-out]="transitionOn" [style.height]="mapImage.height + 'px'" [style.width]="mapImage.width + 'px'">
      <div *ngIf="room" class="pin" [class.fade-in]="transitionOn" [style.top]="room.pinPosition.top + '%'" [style.left]="room.pinPosition.left + '%'">
        <img src="/assets/images/room_pin.svg" [style.height]="(mapImage.width / 9) + 'px'" [style.width]="(mapImage.width / 9) + 'px'">
        <b [class.small-pin]="widthAuto" >{{ room.roomNumber }}</b>
      </div>
    </div>
    <img #mapImage class="center map" [src]="mapImageUrl" [class.width-auto]="widthAuto"/>
  </div>
</ion-content>

<ion-footer *ngIf="showBackButton">
  <button (click)="goBack()">닫기</button>
</ion-footer>