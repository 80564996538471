<ion-header translucent>
  <ion-toolbar>
    <ion-title>
      <ion-img class="toolbar-logo" src="assets/images/app_toolbar_logo_wide.svg"></ion-img>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <div class="home-header">
    <h1>테이크아웃! 고스트키친</h1>
    <p>가까운 지점을 선택해주세요.</p>
  </div>
  <div class="home-content">
    <a *ngFor="let site of homeViewModel"
      (click)="goSite(site._id)"
      class="site-item">
      <div class="image-mask">
        <img [src]="site.thumbnail"/>
      </div>
      <div class="title-box">
        <h3 class="title">
          {{ site.siteName }}
        </h3>
      </div>
    </a>
    <a class="site-item"></a>
    <a class="site-item"></a>
    <a class="site-item"></a>
    <a class="site-item"></a>
  </div>
</ion-content>