<div class="custom-loading-pannel">
  <div class="loading-container">
    <svg height="80" width="52">
      <g class="ghost">
        <g>
          <path class="ghost-fill" d="M37.8,12.7c15,4.8,12.8,36.8,12.8,36.8c-0.3,8.9-1.3,12.1-1.3,12.1c-2.6,7.3-11.8,2.5-12.5-1.1
            c-1.6,6.2-8.7,6.4-8.7,6.4c-7.8,0.7-11.2-6.1-11.2-6.1c-1.7,3.8-4.7,5.4-4.7,5.4c-8.6,3-9.5-10.2-10.1-15
            c0-0.3-5.3-29.5,13.5-39.6c8.1-4.4,15.4-3.8,18-10.4c0,0,3.7,4.1,1,8.6"/>
          <path d="M27,68.2c-5.1,0-8.4-2.9-10-5c-1.8,2.8-4.1,4-4.2,4c-0.1,0-0.1,0.1-0.2,0.1c-2.3,0.8-4.3,0.6-6.1-0.4
            c-4.2-2.6-5.1-10.1-5.6-14.5c0-0.4-0.1-0.8-0.1-1.1l0-0.1c-0.3-2-1.3-9.2-0.2-17.2c0.7-5,2-9.4,4-13.1c2.5-4.7,6-8.2,10.4-10.6
            c2.9-1.6,5.7-2.5,8.2-3.4c4.5-1.5,7.7-2.6,9.2-6.4c0.2-0.4,0.5-0.7,1-0.8c0.4-0.1,0.9,0.1,1.2,0.4c1.5,1.7,3.7,5.9,1.1,10.2
            c-0.4,0.6-1.2,0.8-1.8,0.4c-0.6-0.4-0.8-1.2-0.4-1.8c1.3-2,0.9-4,0.3-5.3c-2.2,3.2-5.7,4.4-9.7,5.8c-2.4,0.8-5,1.7-7.8,3.2
            c-7.2,3.9-11.6,11.2-13,21.8C2.1,42,3,48.9,3.3,50.8c0,0.1,0,0.1,0,0.1h0c0,0.3,0.1,0.7,0.1,1.2c0.4,3.7,1.3,10.6,4.4,12.6
            c1,0.6,2.2,0.7,3.7,0.2c0.4-0.2,2.7-1.7,4.1-4.7c0.2-0.5,0.7-0.8,1.2-0.8c0.5,0,1,0.3,1.2,0.7c0.1,0.2,3.2,6,9.9,5.4
            c0,0,0,0,0.1,0c0.2,0,6.1-0.3,7.5-5.4c0.2-0.6,0.7-1,1.3-1c0.6,0,1.1,0.4,1.3,1c0.3,1.4,3,3.7,5.9,3.9c2,0.1,3.4-0.8,4.1-2.9
            c0.1-0.3,0.9-3.5,1.2-11.7c0,0,0,0,0,0c0-0.3,2-31-11.9-35.5c-0.7-0.2-1.1-1-0.9-1.7c0.2-0.7,1-1.1,1.7-0.9
            c15.7,5,13.8,36.5,13.7,38.1c-0.3,9-1.3,12.2-1.3,12.4c0,0,0,0,0,0.1c-1.1,3.2-3.6,4.9-6.8,4.7c-2.5-0.2-5.2-1.6-6.8-3.3
            c-2.2,3.6-6.5,4.8-8.8,4.9C27.7,68.2,27.4,68.2,27,68.2z M49.3,61.5L49.3,61.5L49.3,61.5z"/>
        </g>
        <path d="M29.3,30.4L29.3,30.4H23c-0.3,0-0.6,0.3-0.6,0.6c0,2.5,2.5,3.1,4,3.1l0,0c0,0,0,0,0,0c3.2,0,3.5-2.6,3.5-3.1c0,0,0,0,0-0.1
          C29.9,30.7,29.7,30.4,29.3,30.4z"/>
        <g>
          <path d="M22.1,49.9c-0.1,0-0.3,0-0.4-0.1c-0.7-0.2-1-1-0.8-1.7c0.6-1.8,1-4.8,1.1-5.3c0-1.9-0.7-2.3-0.9-2.4
            c-1.6-0.9-5.3,0.2-8.1,1.7c-0.1,0-0.2,0.1-0.3,0.1c-0.7,0.2-1.4-0.2-1.6-0.9c-0.2-0.6,0.1-1.3,0.7-1.5l0,0c0.6-0.3,2.2-1.1,4-1.7
            c2.9-0.9,5.1-0.9,6.7,0c1,0.6,2.2,1.9,2.2,4.7l0,0.1c0,0.2-0.4,3.8-1.2,6C23.1,49.6,22.6,49.9,22.1,49.9z"/>
        </g>
        <g>
          <path d="M31.1,49.6c-0.5,0-1-0.3-1.2-0.9c-0.8-2.2-1.2-5.8-1.2-6l0-0.1c0-2.8,1.2-4.1,2.2-4.7c1.6-0.9,3.8-1,6.7,0
            c1.8,0.6,3.4,1.4,4,1.7l0,0c0.6,0.3,0.9,0.9,0.7,1.5c-0.2,0.7-0.9,1.1-1.6,0.9c-0.1,0-0.2-0.1-0.3-0.1c-2.8-1.5-6.6-2.7-8.1-1.7
            c-0.2,0.1-0.9,0.6-0.9,2.4c0.1,0.5,0.4,3.6,1.1,5.3c0.2,0.7-0.1,1.4-0.8,1.7C31.4,49.6,31.2,49.6,31.1,49.6z"/>
        </g>
        <g>
          <path d="M33.8,23c-0.6-0.9-1.4-1.8-2.5-2c-1.1-0.2-2.2,0.3-2.8,1.3c-0.1,0.1-0.2,0.3-0.2,0.4c-0.5,1.9-0.4,3.9,1,5.4
            c1,1.1,2.7,1.8,4.1,1c0.8-0.4,1.5-1.5,1.7-2.4C35.3,25.4,34.4,24,33.8,23z"/>
          <path d="M23.7,22.3c-0.6-0.9-1.5-1.4-2.6-1.3c-1.2,0.2-2.1,1.1-2.7,2c-0.6,0.9-1.5,2.3-1.3,3.5c0.1,0.9,0.9,2.1,1.7,2.6
            c1.4,0.9,3.1,0.1,4.1-1c1.4-1.5,1.5-3.5,1-5.4C23.9,22.5,23.8,22.4,23.7,22.3z"/>
        </g>
      </g>
      <ellipse class="ghost-shadow" cx="27.7" cy="75.7" rx="21.2" ry="4.3"/>
    </svg>
  </div>
</div>