<ion-header>
	<ion-toolbar>
		<app-back-button [defaultHref]="defaultHref"></app-back-button>
		<ion-title>주문내역</ion-title>
	</ion-toolbar>
</ion-header>

<ion-content *ngIf="initialized" fullscreen>
	<div class="user-info" *ngIf="confirmAuth">
		<p class="user-tel"><span>전화번호 : </span>{{ userTel }}</p>
		<button (click)="resetConfirm()">인증정보 삭제</button>
		<p><span>총 주문수 : </span>{{ orderList.length }}건</p>
	</div>
	<div class="divider" *ngIf="confirmAuth"></div>

	<div class="order-list" *ngIf="confirmAuth && orderList.length > 0">
		<div
			class="order-item"
			*ngFor="let order of orderList"
			(click)="goToReceipt(order.orderNo)"
		>
			<div class="item-content" [class.add-top-padding]="order.timer">
				<div *ngIf="order.timer" class="cook-minutes">
					<span>{{ order.orderStatus }}</span>
					<span
						>조리 완료까지 <b>{{ order.timer }}분</b> 남았습니다</span
					>
					<ion-progress-bar
						buffer="0"
						color="ghostkitchen"
						[value]="1 - order.timer / order.cookMinutes"
					></ion-progress-bar>
				</div>
				<p
					*ngIf="!order.timer"
					class="order-status code-{{ order.contextStatusCode }}"
				>
					{{ order.orderStatus }}
				</p>
				<p class="order-title">
					<b>{{ order.site }} {{ order.roomNo | number }}호</b> -
					{{ order.shopName }}
				</p>
				<p class="food" *ngFor="let food of order.foods">· {{ food }}</p>
				<p class="amount">{{ order.orderAmount | number }}원</p>
				<p class="order-date">{{ order.orderDate | date: "MM-dd HH:mm" }}</p>
			</div>
			<ion-icon
				class="arrow-right"
				name="chevron-forward-outline"
				color="medium"
			></ion-icon>
		</div>
	</div>

	<div class="auth-form" *ngIf="!confirmAuth">
		<div class="inform">
			<p>주문내역 확인을 위해 <b>전화번호를 인증</b>해주세요.</p>
		</div>

		<div class="input-container">
			<p class="input-label">전화번호</p>
			<div class="input-content left-side">
				<input
					[formControl]="userTelForm"
					type="tel"
					(keydown.enter)="sendAuthSMS()"
				/>
			</div>
			<div class="input-content right-side">
				<button
					(click)="sendAuthSMS()"
					[class.send-again]="sessionId"
					[disabled]="userTelForm.invalid"
				>
					{{ sessionId ? "재전송" : "인증문자 전송" }}
				</button>
			</div>
			<p class="input-error">
				{{ userTelForm.invalid ? "· " + userTelForm.errors.reason : " " }}
			</p>
		</div>

		<div class="input-container" *ngIf="sessionId && !confirmAuth">
			<p class="input-label">인증번호 입력</p>
			<div class="input-content left-side">
				<input
					[formControl]="authCodeForm"
					type="tel"
					(keydown.enter)="confirmSMSCode()"
				/>
			</div>
			<div class="input-content right-side">
				<button (click)="confirmSMSCode()" [disabled]="authCodeForm.invalid">
					인증번호 확인
				</button>
			</div>
			<p class="input-error">
				{{ authCodeForm.invalid ? "· " + authCodeForm.errors.reason : " " }}
			</p>
		</div>
	</div>
</ion-content>
