/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IonContent } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { ViewOption } from '../../schema/1/schema-view-model';
import { SiteDoc } from '../../schema/3/schema';

import { environment } from '../../../environments/environment';
import { UtilService } from '../../core/1/util.service';
import { trimOrganization } from '../../core/2/util';
import { SiteService } from '../../core/3/site.service';
import { ShoppingCartService } from '../shopping-cart/shopping-cart.service';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss']
})
export class SitesComponent implements OnInit, OnDestroy {
  @ViewChild('scrollArea', { static: true }) ionContent: IonContent;
  public payable = environment.payable;

  public siteName: string;
  public siteDoc: SiteDoc;

  public viewOption: ViewOption = ViewOption.SHOP;
  public productsInCart = 0;

  private destroySignal = new Subject<void>();

  constructor(
    private shoppingCartService: ShoppingCartService,
    private siteSerivce: SiteService,
    private router: Router,
    private route: ActivatedRoute,
    private utilService: UtilService,
  ) { }

  ngOnInit() {
    this.initialize();
  }

  ngOnDestroy() {
    this.destroySignal.next();
    this.destroySignal.unsubscribe();
  }

  initialize() {
    this.observeRouter();
    this.observeShoppingCart();
  }

  /******************************************************************
   * [initialize & observing]
   ******************************************************************/
  observeRouter() {
    this.route.params
      .pipe(takeUntil(this.destroySignal))
      .subscribe(param => {
        const { site } = param;
        const siteDoc = this.siteSerivce.site[site];

        if (siteDoc) {
          document.title = siteDoc.siteName;
          this.siteName = trimOrganization(siteDoc.siteName);
        } else {
          this.utilService.toastError('잘못된 주소입니다.', undefined, 5000);
          this.router.navigateByUrl('');
        }

        this.siteDoc = siteDoc;
        // Reset Shopping-cart State
        if (this.shoppingCartService.shopDetail && this.shoppingCartService.shopDetail.site !== site) {
          this.shoppingCartService.emptyCart();
        }
      });
  }

  observeShoppingCart() {
    this.shoppingCartService.latestUnifiedOrderFoodsSubject
      .pipe(takeUntil(this.destroySignal))
      .subscribe(unifiedOrderFoods => {
        this.productsInCart = unifiedOrderFoods.reduce((sum, food) => sum + food.foodQty, 0);
      })
      ;
  }

  selectViewOption(option: ViewOption | string) {
    this.viewOption = option as ViewOption;
  }

  segmentChanged(event: CustomEvent) {
    const { value } = event.detail;
    this.viewOption = value;
  }

  /******************************************************************
   * [Routing]
   ******************************************************************/
  goShoppingCart() {
    this.router.navigate(['./cart'], { relativeTo: this.route });
  }

  goShop(shopNo: string) {
    this.router.navigate([`/${this.siteDoc._id}/${shopNo}`]);
  }

  goToMyOrder() {
    this.router.navigateByUrl('my-order', { state: { site: this.siteDoc._id } });
  }

  openDestMap() {
    const { lat, lng, siteName } = this.siteDoc;
    const placeName = encodeURI(`${siteName} (지하1층)`);
    const url = 'http://map.daum.net/link/map/' + placeName + `,${lat},${lng}`;

    // 상세 내용 추가
    // refer : http://apis.map.daum.net/web/guide/#bigmapurl
    // http://map.daum.net/?urlX=506255.0&urlY=1113450.0&name=%EB%85%BC%ED%98%84%EB%8F%99%2B170-14
    window.open(
      url,
      'centerscreen,alwaysRaised,noreferrer'
    );
  }

  scrollToTop(duration?: number) {
    this.ionContent.scrollToTop(duration);
  }
}
