/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import fecha from 'fecha';
import { Subscription, timer, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';

import { LocalStorageService } from '../../shared/local-storage/local-storage.service';
import { LoadingService } from '../../shared/loading/loading.service';

import { UnifiedOrder } from '../../schema/3/schema';

import { GtagService } from '../../core/1/gtag.service';
import { UtilService } from '../../core/1/util.service';
import { getRequestAuth, getConfirmAuth } from '../../core/1/sms-api';
import { normalizingTel } from '../../core/2/util';
import { diffTime, trimOrganization } from '../../core/2/util';
import { SiteService } from '../../core/3/site.service';
import { LogService } from '../../core/3/log.service';
import { UnifiedOrderService } from '../../core/4/unified-order.service';

interface MyOrderViewModel {
  orderNo: string;
  roomNo: string;
  site: string;
  shopName: string;
  orderAmount: number;
  contextStatusCode: number;
  orderStatus: string;
  orderDate: number;
  foods: string[];
  cookMinutes: number;
  timer?: number;
}

@Component({
  selector: 'app-my-order',
  templateUrl: './my-order.component.html',
  styleUrls: ['./my-order.component.scss']
})
export class MyOrderComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroySignal = new Subject<void>();
  monthRange = 6;
  defaultHref = '';

  orderStatusMap = new Map<number, string>([
    [10, '주문완료'],
    [20, '접수완료'],
    [40, '조리완료'],
    [70, '픽업완료'],
    [80, '주문취소']
  ]);
  userTelForm: FormControl<string>;
  authCodeForm: FormControl<string>;

  initialized = false;
  confirmAuth = false;
  unifiedOrderForUserTel: UnifiedOrder[] = [];
  orderList: MyOrderViewModel[] = [];

  userTel: string;
  sessionId: string;
  expires: string;

  private unifiedOrderForUserTelSubscription: Subscription;

  constructor(
    private localStorageService: LocalStorageService,
    private unifiedOrderService: UnifiedOrderService,
    private siteService: SiteService,
    private loadingService: LoadingService,
    private gtagService: GtagService,
    private utilService: UtilService,
    private logService: LogService,
    private location: Location,
    private router: Router
  ) { }

  ngOnInit() {
    const { site } = this.location.getState() as { site: string };
    this.defaultHref = site ? site : '';
    this.userTelForm = new FormControl({ value: '', disabled: this.confirmAuth }, this.userTelValidator());
    this.authCodeForm = new FormControl({ value: '', disabled: this.confirmAuth }, this.authCodeValidator());
    this.initialization();
  }

  ngOnDestroy() {
    // 사용중이기 때문에 destorySingal과 분리
    if (this.unifiedOrderForUserTelSubscription) {
      this.unifiedOrderForUserTelSubscription.unsubscribe();
      this.unifiedOrderForUserTelSubscription = undefined;
    }

    this.destroySignal.next();
    this.destroySignal.unsubscribe();
  }

  async initialization() {
    const userTel = this.checkUserTel();

    if (userTel) {
      let confirmAuth = false;

      // 인증된 번호와 목록이 있는 사용자는 재인증을 건너뛴다.(인증 소요시간을 건너뛰기 위함)
      if ((userTel === this.unifiedOrderService.userTel)
        && (this.unifiedOrderService.unifiedOrderForUserTel)
        && (this.unifiedOrderService.unifiedOrderForUserTel.length > 0)) {
        confirmAuth = true;
        this.observeUnifiedOrder();
      } else {
        await this.loadingService.presentLoading();
        confirmAuth = await this.autoConfirm();
        await this.loadingService.dismissLoading();
        if (confirmAuth) {
          const now = new Date();
          const endDate = fecha.format(new Date(now.setMonth(now.getMonth() - this.monthRange)), `YYYY-MM-DDTHH:mm:ss+09:00`);
          this.unifiedOrderService.observeOrderFor(userTel, endDate);

          this.observeUnifiedOrder();
        }
      }

      this.userTel = normalizingTel(userTel);
      this.userTelForm.setValue(userTel);
      this.confirmAuth = confirmAuth;
      if (confirmAuth) {
        this.userTelForm.disable();
        this.authCodeForm.disable();
      } else {
        this.userTelForm.enable();
        this.authCodeForm.enable();
      }
    }
    this.initialized = true;
  }

  observeUnifiedOrder() {
    this.unifiedOrderForUserTelSubscription = this.unifiedOrderService.latestUnifiedOrderForUserTelSubject.subscribe(orderDoc => {
      this.orderList = orderDoc
        .filter(order => (
          // 삭제, 결제대기는 주문 내역에서 제외한다.
          this.orderStatusMap.get(order.contextStatusCode)
          // 직접방문, 손가락 주문내역만을 보여준다.
          && ((order.createdBy === 'face') || (order.createdBy === 'fingerFace'))
        ))
        .map(order => {
          const fechaDate = fecha.format(fecha.parse(order.orderDate, 'YYYY-MM-DDTHH:mm:ssZZ'), 'YYYY-MM-DDTHH:mm:ss+09:00');
          const orderDate = Date.parse(fechaDate);
          const calculatedTime = (order.contextStatusCode === 20)
            ? this.calculatePickupTime(Date.parse(fechaDate), order.cookMinutes)
            : 0;

          return {
            orderNo: order.orderNo,
            roomNo: order.room.split('-')[2],
            site: trimOrganization(this.siteService.site[order.site].siteName),
            shopName: order.shopName,
            orderAmount: order.orderAmount,
            contextStatusCode: order.contextStatusCode,
            orderStatus: this.orderStatusMap.get(order.contextStatusCode),
            orderDate,
            foods: order.foods.map(food => food.mergedName),
            cookMinutes: order.cookMinutes,
            timer: calculatedTime > 0 ? calculatedTime : undefined
          };
        })
        .sort((a, b) => b.orderDate - a.orderDate);
    });
  }

  ngAfterViewInit() {
    timer(0, 5000)
      .pipe(takeUntil(this.destroySignal))
      .subscribe(() => {
        this.orderList.forEach(order => {
          if (order.contextStatusCode === 20) {
            const calculatedTime = this.calculatePickupTime(order.orderDate, order.cookMinutes);
            order.timer = calculatedTime > 0 ? calculatedTime : undefined;
          }
        });
      });
  }

  calculatePickupTime(orderDate: number, cookMinutes: number) {
    const now = new Date();
    const cookDate = new Date(orderDate + cookMinutes * 60000);
    const diff = diffTime(now, cookDate);
    return diff.m;
  }

  userTelValidator(): ValidatorFn {
    return (control: FormControl<string>): ValidationErrors | null => {
      const { value } = control;

      const normalizedTel = normalizingTel(value);
      if (value !== normalizedTel) {
        this.userTelForm.setValue(normalizedTel);
      }

      if (value && value.length > 0) {
        const match = value.match(/^(0[157]0[1-9]?-[1-9][0-9]{3,4}-[0-9]{4}|02-[2-9][0-9]{2,3}-[0-9]{4})$/);
        if (match === null) {
          return { reason: '전화번호가 형식에 맞지 않습니다.' };
        }
      } else {
        return { reason: '전화번호가 필요합니다.' };
      }
    };
  }

  authCodeValidator(): ValidatorFn {
    return (control: FormControl<string>): ValidationErrors | null => {
      const { value } = control;

      if (!value || value.length === 0) {
        return { reason: '인증번호를 입력해주세요.' };
      }
    };
  }

  // UserTel의 유무 및 전화번호 형식 체크
  checkUserTel() {
    if (!this.localStorageService.isExist('userTel')) { return false; }

    const userTel = this.localStorageService.getValue('userTel') as string;
    const normalizedTel = normalizingTel(userTel);
    const match = normalizedTel.match(/^(0[157]0[1-9]?-[1-9][0-9]{3,4}-[0-9]{4}|02-[2-9][0-9]{2,3}-[0-9]{4})$/);
    if (match === null) {
      // 잘못된 형식의 전화번호가 저장되어있는 경우 삭제한다.
      this.localStorageService.removeItem('userTel');
    }
    return userTel;
  }

  async autoConfirm() {
    if (!this.localStorageService.isExist('authSMS')) { return false; }
    if (!this.localStorageService.isExist('expires')) { return false; }
    const localExpires = this.localStorageService.getValue('expires') as string;
    const localAuthSMS = this.localStorageService.getValue('authSMS') as { sessionId: string, authCode: string };

    if (localAuthSMS.sessionId === undefined) {
      this.logService.error(`MyOrder::local의 데이터가 undefined ${localAuthSMS}`);
      // 유요하지 않은 인증정보는 모두 삭제한다.
      this.localStorageService.removeItem('expires');
      this.localStorageService.removeItem('authSMS');
      return false;
    }

    const isExpired = new Date().getTime() > new Date(localExpires).getTime();
    if (!isExpired) {
      try {
        const response = await getConfirmAuth(localAuthSMS.sessionId, localAuthSMS.authCode);
        const { result, reason, expires } = await response.json() as { result: string, reason: string | null, expires: string };

        if (result !== 'success') {
          this.logService.info(`재인증 실패 sessionId: ${localAuthSMS.sessionId}, authCode: ${localAuthSMS.authCode} : ${reason}`);
        } else {
          const newExpires = fecha.format(fecha.parse(expires, 'YYYY-MM-DDTHH:mm:ssZZ'), 'YYYY-MM-DDTHH:mm:ss+09:00');
          this.localStorageService.setItem('expires', newExpires);
          return true;
        }
      } catch (error) {
        this.logService.error(`MyOrder::authConfirm에서 에러 발생 : ${error}`);
      }
    }

    // 유요하지 않은 인증정보는 모두 삭제한다.
    this.localStorageService.removeItem('expires');
    this.localStorageService.removeItem('authSMS');
    return false;
  }

  async resetConfirm() {
    await this.loadingService.presentLoading();

    try {
      this.localStorageService.removeItem('userTel');
      this.localStorageService.removeItem('expires');
      this.localStorageService.removeItem('authSMS');
      this.userTel = undefined;
      this.expires = undefined;
      this.sessionId = undefined;

      this.confirmAuth = false;
      this.userTelForm.setValue('');
      this.userTelForm.enable();
      this.authCodeForm.enable();
    } catch (error) {
      this.logService.error(`resetConfirm에서 에러 발생 : ${error}`);
    } finally {
      if (this.loadingService.loadingElement) {
        await this.loadingService.dismissLoading();
        this.loadingService.loadingElement = undefined;
      }
    }
  }
  /******************************************************************
   * [전화번호 문자인증]
   * 1. sendAuthSMS()     인증 문자 발송
   * 2. confirmSMSCode()  인증 문자 확인
   ******************************************************************/
  async sendAuthSMS() {
    const userTel = this.userTelForm.value.replace(/-/g, '');

    try {
      await this.loadingService.presentLoading();
      this.gtagService.page('/req-auth');
      const response = await getRequestAuth(userTel);

      const { sessionId } = await response.json() as { sessionId: string };
      this.sessionId = sessionId;
      this.authCodeForm.setValue('');
      this.logService.info(`문자전송 성공 ${userTel}`);
    } catch (error) {
      this.utilService.toastError(`문자 전송에 실패했습니다. ${error}`);
      this.logService.error(`문자전송 실패 ${userTel} :; ${error}`);
    } finally {
      if (this.loadingService.loadingElement) {
        await this.loadingService.dismissLoading();
        this.loadingService.loadingElement = undefined;
      }
    }
  }

  async confirmSMSCode() {
    const userTel = this.userTelForm.value.replace(/-/g, '');
    const authCode = this.authCodeForm.value;

    try {
      this.loadingService.presentLoading();
      this.gtagService.page('/confirm-auth');
      const response = await getConfirmAuth(this.sessionId, authCode);

      const { result, reason, expires } = await response.json() as { result: string, reason: string | null, expires: string };
      // Safari는 +0900 형태에 대해서 에러를 리턴하기 때문에 parse후에 format을 한다.
      this.expires = fecha.format(fecha.parse(expires, 'YYYY-MM-DDTHH:mm:ssZZ'), 'YYYY-MM-DDTHH:mm:ss+09:00');
      this.confirmAuth = (result === 'success');

      if (reason !== null) {
        const msg = (reason === 'autoCode mismatch') ? '잘못된 인증문자입니다.<br>다시 입력해주세요.' : reason;
        this.utilService.toastInfo(msg, undefined, 3000);
        this.logService.info(`문자인증 실패 ${userTel}; : ${reason}`);
      } else {
        this.logService.info(`문자인증 성공 ${userTel}`);
        this.saveLocalUserState();
        this.userTel = normalizingTel(userTel);
        // 인증이 완료된 전화번호에 해당하는 주문 정보를 불러온다.
        const now = new Date();
        const endDate = fecha.format(new Date(now.setMonth(now.getMonth() - this.monthRange)), 'YYYY-MM-DDTHH:mm:ss+09:00');
        this.unifiedOrderService.observeOrderFor(userTel, endDate);
        if (this.unifiedOrderForUserTelSubscription) {
          this.unifiedOrderForUserTelSubscription.unsubscribe();
          this.unifiedOrderForUserTelSubscription = undefined;
        }
        this.observeUnifiedOrder();
      }

    } catch (error) {
      this.logService.error(`문자인증에서 에러 발생 _ ${userTel} : ${error}`);
      this.utilService.toastError(`오류가 발생했습니다. 잠시후 다시 시도해주세요. ${error}`);
    } finally {
      await this.loadingService.dismissLoading();
    }
  }

  saveLocalUserState() {
    try {
      const userTel = this.userTelForm.value.replace(/-/g, '');
      const authCode = this.authCodeForm.value;

      this.localStorageService.setItem('userTel', userTel);
      this.localStorageService.setItem('expires', this.expires);
      this.localStorageService.setItem('authSMS', {
        sessionId: this.sessionId,
        authCode
      });
    } catch (error) {
      this.logService.error(`/my-order saveLocalUserState : ${error}`);
    }

    // 인증 완료 및 저장까지 완료되어 입력창을 막는다.
    this.userTelForm.disable();
    this.authCodeForm.disable();
  }

  goToReceipt(orderNo: string) {
    this.router.navigateByUrl(`/my-order/${orderNo}`);
  }
}
