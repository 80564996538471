/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { combineLatest, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

import { ShopViewModel, ViewModelForSite, MenuViewModel } from '../../schema/1/schema-view-model';
import { UnifiedMenuDoc, RoomDoc } from '../../schema/3/schema';

import { baeminCategoryColorMap } from '../1/string-map';
import { UnifiedMenuService } from '../1/unified-menu.service';
import { RoomService } from '../3/room.service';
import { ConfService } from '../4/conf.service';

// tslint:disable-next-line:max-line-length
const ktStaticPrefix = 'https://ssproxy.ucloudbiz.olleh.com/v1/AUTH_d722d13e-44ea-44ad-8c9b-2f5763ce3d40/ghostkitchen/toe-menu-images';
@Injectable({
  providedIn: 'root'
})
export class ViewModelService {
  public currentSite: string;
  public viewModelForSite: ViewModelForSite = {};
  public latestShopViewModelForSiteSubject = new BehaviorSubject<ViewModelForSite | undefined>(undefined);

  private priceLimit = 0;
  private words: string[] = [];

  constructor(
    private unifiedMenuService: UnifiedMenuService,
    private roomService: RoomService,
    private confService: ConfService
  ) { }

  // UnifiedMenu다음으로 구독 시작
  observeViewModel() {
    const unifiedMenuSubject = this.unifiedMenuService.latestUnifiedMenuForSiteSubject;
    const roomSubject = this.roomService.latestSubject;

    combineLatest([unifiedMenuSubject, roomSubject]).subscribe(([unifiedMenuDocs, roomDocs]) => {
      if (unifiedMenuDocs.length > 0) {
        const currentSite = this.unifiedMenuService.currentSite;
        // 모든 메뉴에서 제외하는 리스트를 가져온다.
        this.words = this.confService.fingerFaceConf.menuFilter.words;
        this.priceLimit = this.confService.fingerFaceConf.menuFilter.priceLimit;

        // 1. Room을 따른 1차 filter
        const liveRooms = Object.values(roomDocs)
          .filter(roomDoc => (
            roomDoc.site === currentSite
            // 업소의 '운영상태'가 true(운영)인가
            && roomDoc.live
            // 테스트 업소가 아닌가
            && !roomDoc.virtual
            // '손가락 노출'을 꺼놓았는가(값이 없는 경우는 true로 간주하기 위해 false값만 확인한다: is not in blacklist)
            && roomDoc.enableFingerFace !== false
          ));

        // 2. UnifiedMenu에 따른 2차 filter
        const filteredUnifiedMenuDocs = unifiedMenuDocs.filter(unifiedMenu => (
          // 손가락을 사용하며 운영준인 업소인가?
          liveRooms.find(room => room._id === unifiedMenu.room)
          // (대표) 메뉴 그룹에 메뉴가 존재하는가
          && unifiedMenu.menus?.[0]?.foods
          // 배민에서 광고를 사용중인가?
          // 2020-11-26 나중에 Ad_Yn 필드를 추가해서 없는 경우도 있을 수 있기 때문에 'N'이 아닌 경우는 허용하도록 했다. 나중에는 'Y'인 경우만 허용하도록 한다.
          && unifiedMenu.baeminShopInfo?.Ad_Yn !== 'N'
          // 배민인가 (배민1이 아니라)
          && unifiedMenu.baeminShopInfo.Ct_Ty_Cd === '1'
        ));

        const rawMenuViewModel = this.createMenuViewModel(filteredUnifiedMenuDocs, liveRooms);
        const menuViewModel = this.removeDuplicatedMenu(rawMenuViewModel);

        const rawShopViewModel = this.createShopViewModel(filteredUnifiedMenuDocs, liveRooms);
        const shopViewModel = this.removeDuplicatedShop(rawShopViewModel).sort((x, y) => {
          return (x.isAvailable === y.isAvailable) ? 0 : x.isAvailable ? -1 : 1;
        });

        this.currentSite = currentSite; // view component에서 사용하기 위해 저장한다.
        this.viewModelForSite[currentSite] = { shopViewModel, menuViewModel };

        this.latestShopViewModelForSiteSubject.next(this.viewModelForSite);
      } else {
        // 아직 데이터를 가져오지 못한경우 undefined
        this.latestShopViewModelForSiteSubject.next(undefined);
      }
    });
  }

  private createMenuViewModel(unifiedMenuDocs: UnifiedMenuDoc[], liveRooms: RoomDoc[]) {
    const rex = new RegExp(`^.*(${this.words.join('|')}).*`);
    return unifiedMenuDocs
      // 배민에서 주문이 불가능한 메뉴는 노출하지 않는다.(order available - 업소가 배민에 설정한 내용으로 원 데이터는 baeminUserDetailShop에 있다.)
      .filter(unifiedMenu => (
        /** 2021.01.07 배민 운영 상태를 무시하는 '손가락 강제 오픈' 추가 */
        (liveRooms.find(room => room._id === unifiedMenu.room)?.forceFingerOpen === true)
        || unifiedMenu.baeminShopInfo?.Ord_Avail_Yn === 'Y'))
      .flatMap(unifiedMenu => {
        // menus -> menu -> foods -> food
        // 메뉴의 최소 단위인 food로 배열을 만든다.
        return unifiedMenu.menus
          .flatMap((menu, menuIndex) => menu.foods.map((food, foodIndex) => ({
            ...food,
            roomNo: Number(unifiedMenu.room.split('-')[2]),
            shopNo: unifiedMenu.shopNo,
            foodNo: menuIndex * 100 + foodIndex, // 각 food에 대한 고유 ID 역할
            category: unifiedMenu.baeminShopInfo?.Ct_Cd_Nm,
          }))).flat();
      })
      .filter(food => (
        !food.foodSoldOut
        // 손가락 메뉴의 최소가격 제한에 들어가며 동시에 blacklist에 포함되는지를 확인한다.
        // ex) 가격이 4000원 이하이면서 '콜라'또는 '사이다'인 경우에는 전체 메뉴보기에서 제외한다.
        && ((food.foodOptGroups[0].foodOpts[0].optPrice <= this.priceLimit)
          ? !rex.test(food.foodName)
          : true)
      ))
      // UI에서 사용할 음식 이미지(baemin URL -> kt URL), 카테고리별 컬러링, 기본 가격, 태그등을 설정한다.
      .map(food => ({
        roomNo: food.roomNo,
        shopNo: food.shopNo,
        foodNo: food.foodNo,
        category: food.category,
        foodName: food.foodName,
        imageUrl: food.imageUrl ? `${ktStaticPrefix}${food.imageUrl.split('.com')[1]}` : undefined,
        color: baeminCategoryColorMap[food.category] ?? '#EA0029',
        price: food.foodOptGroups[0].foodOpts[0].optPrice,
        hashtag: [food.foodName, food.category]
      }));
  }

  private createShopViewModel(unifiedMenuDocs: UnifiedMenuDoc[], liveRooms: RoomDoc[]) {
    return unifiedMenuDocs
      .map(unifiedMenu => {
        const room = liveRooms.find(roomDoc => roomDoc._id === unifiedMenu.room);
        const imageUrl = unifiedMenu.menus[0].foods[0].imageUrl;
        return {
          /** 2021.01.07 배민 운영 상태를 무시하는 '손가락 강제 오픈' 추가 */
          isAvailable: unifiedMenu.baeminShopInfo && (room?.forceFingerOpen === true || unifiedMenu.baeminShopInfo.Ord_Avail_Yn === 'Y'),
          shopName: unifiedMenu.shopName,
          shopNo: unifiedMenu.shopNo,
          roomKey: unifiedMenu.room,
          roomNo: Number(unifiedMenu.room.split('-')[2]),
          section: room?.section,
          thumbnailUrl: imageUrl ? `${ktStaticPrefix}${imageUrl.split('.com')[1]}` : undefined,
        };
      });
  }

  // 호실 또는 업소의 이름이 같은 경우 최초 하나만 노출한다.
  private removeDuplicatedShop(shopViewModel: ShopViewModel[]) {
    return shopViewModel.filter((a, index) => (
      index === shopViewModel.findIndex(b => (a.roomKey === b.roomKey) && (a.shopName === b.shopName))
    ));
  }

  // 메뉴 이름이 같은 경우 최초 하나만 노출한다.
  private removeDuplicatedMenu(menuViewModel: MenuViewModel[]) {
    return menuViewModel.filter((a, index) => (
      index === menuViewModel.findIndex(b => (a.foodName.replace(/\s/g, '') === b.foodName.replace(/\s/g, '')))
    ));
  }
}
