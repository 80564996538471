/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
export const RoomPosForMap = {
  /** 강남역 */
  'gk-kangnam-01': [
    73.72182227221597,
    74.39882389353141
  ],
  'gk-kangnam-10': [
    90.06299212598425,
    40.19300526152893
  ],
  'gk-kangnam-11': [
    82.64033745781778,
    40.19300526152893
  ],
  'gk-kangnam-12': [
    74.54771653543307,
    40.19300526152893
  ],
  'gk-kangnam-13': [
    61.235230596175484,
    40.19300526152893
  ],
  'gk-kangnam-14': [
    53.51415073115861,
    40.19300526152893
  ],
  'gk-kangnam-15': [
    45.614263217097864,
    40.19300526152893
  ],
  'gk-kangnam-16': [
    85.20571428571428,
    18.462055091303004
  ],
  'gk-kangnam-17': [
    74.41617547806524,
    18.462055091303004
  ],
  'gk-kangnam-18': [
    53.312283464566924,
    18.956607861343237
  ],
  'gk-kangnam-19': [
    45.203869516310455,
    18.956607861343237
  ],
  'gk-kangnam-02': [
    48.04679415073116,
    84.1207675642216
  ],
  'gk-kangnam-20': [
    75.11523059617548,
    -2.0651810584958223
  ],
  'gk-kangnam-21': [
    67.49835770528684,
    -2.0651810584958223
  ],
  'gk-kangnam-22': [
    48.623329583802025,
    -2.0651810584958223
  ],
  'gk-kangnam-23': [
    32.44409448818898,
    5.1612813370473525
  ],
  'gk-kangnam-24': [
    24.453858267716537,
    5.1612813370473525
  ],
  'gk-kangnam-25': [
    16.403307086614173,
    5.1612813370473525
  ],
  'gk-kangnam-26': [
    8.380967379077616,
    5.1612813370473525
  ],
  'gk-kangnam-27': [
    0.6797525309336336,
    5.1612813370473525
  ],
  'gk-kangnam-03': [
    48.040067491563555,
    73.87319715258434
  ],
  'gk-kangnam-04': [
    51.928458942632176,
    64.02695759826679
  ],
  'gk-kangnam-05': [
    32.711968503937,
    69.01488703187867
  ],
  'gk-kangnam-06': [
    25.457525309336337,
    69.01488703187867
  ],
  'gk-kangnam-07': [
    4.052125984251969,
    84.13240482822654
  ],
  'gk-kangnam-08': [
    3.9913835770528667,
    73.81668214175178
  ],
  'gk-kangnam-09': [
    8.267739032620923,
    64.0738780563293
  ],

  // 삼성
  'gk-samsung-01': [
    2.2415659851301113,
    75.26286624203823
  ],
  'gk-samsung-10': [
    51.47448884758365,
    24.171847133757964
  ],
  'gk-samsung-11': [
    64.59042750929369,
    19.960668789808913
  ],
  'gk-samsung-12': [
    83.702718401487,
    35.56722929936306
  ],
  'gk-samsung-13': [
    83.6957249070632,
    22.94608280254777
  ],
  'gk-samsung-14': [
    83.67832249070632,
    10.92971337579618
  ],
  'gk-samsung-15': [
    83.7003717472119,
    -1.6887261146496808
  ],
  'gk-samsung-02': [
    12.395120817843868,
    75.26286624203823
  ],
  'gk-samsung-03': [
    22.486919144981414,
    75.26286624203823
  ],
  'gk-samsung-04': [
    32.56826208178439,
    75.26286624203823
  ],
  'gk-samsung-05': [
    42.68910315985131,
    75.26286624203823
  ],
  'gk-samsung-06': [
    52.7878717472119,
    75.26286624203823
  ],
  'gk-samsung-07': [
    62.936593866170995,
    75.26286624203823
  ],
  'gk-samsung-08': [
    33.70799256505577,
    24.171910828025474
  ],
  'gk-samsung-09': [
    42.87946096654275,
    24.171910828025474
  ],

  // 송파
  'gk-songpa-01': [
    0.6377049180327861,
    85.78942131448024
  ],
  'gk-songpa-02': [
    -0.09877699713765331,
    68.68588635768526
  ],
  'gk-songpa-03': [
    9.651053864168617,
    68.68588635768526
  ],
  'gk-songpa-04': [
    19.08207129846474,
    68.68588635768526
  ],
  'gk-songpa-05': [
    -0.1013791308873273,
    56.748442000523696
  ],
  'gk-songpa-06': [
    9.656258131667968,
    56.748442000523696
  ],
  'gk-songpa-07': [
    19.162763466042154,
    56.748442000523696
  ],
  'gk-songpa-08': [
    -0.11569086651053873,
    39.57859387274156
  ],
  'gk-songpa-09': [
    9.656258131667968,
    39.57859387274156
  ],
  'gk-songpa-10': [
    18.443247462919594,
    39.57859387274156
  ],
  'gk-songpa-11': [
    -0.46697892271662766,
    27.069795758051853
  ],
  'gk-songpa-12': [
    9.040853499869893,
    27.069795758051853
  ],
  'gk-songpa-13': [
    18.483580536039554,
    27.069795758051853
  ],
  'gk-songpa-14': [
    9.5175904241478,
    10.505682115737105
  ],
  'gk-songpa-15': [
    18.488784803538902,
    10.505682115737105
  ],
  'gk-songpa-16': [
    -0.231225604996097,
    -0.30272322597538537
  ],
  'gk-songpa-17': [
    40.077283372365336,
    16.292694422623722
  ],
  'gk-songpa-18': [
    40.007025761124126,
    11.850314218381776
  ],
  'gk-songpa-19': [
    59.796695290137905,
    74.60890285415032
  ],
  'gk-songpa-20': [
    60.235128805620604,
    62.23242995548573
  ],
  'gk-songpa-21': [
    60.533125162633354,
    52.31347211311862
  ],
  'gk-songpa-22': [
    60.283268279989585,
    39.87448284891333
  ],
  'gk-songpa-23': [
    83.78524590163933,
    72.18840010473946
  ],
  'gk-songpa-24': [
    83.76575591985429,
    67.69219691018593
  ],
  'gk-songpa-25': [
    83.7903981264637,
    63.432835820895534
  ],

  // 노원
  'gk-nowon-01': [
    25.98211995473406,
    3.123207419135941
  ],
  'gk-nowon-02': [
    9.337759336099584,
    28.10832390861796
  ],
  'gk-nowon-03': [
    4.636552244436061,
    46.24128025333634
  ],
  'gk-nowon-04': [
    20.560580912863067,
    46.241235014702546
  ],
  'gk-nowon-05': [
    36.922783855149,
    46.241235014702546
  ],
  'gk-nowon-06': [
    78.32527348170501,
    10.845464826962225
  ],
  'gk-nowon-07': [
    78.40641267446246,
    23.256548292241575
  ],
  'gk-nowon-08': [
    78.26846473029045,
    32.522257407826274
  ],
  'gk-nowon-09': [
    78.28928706148622,
    41.653698258312595
  ],
  'gk-nowon-10': [
    77.32746133534515,
    50.72920153811355
  ],
  'gk-nowon-11': [
    77.75929837797057,
    59.8047500565483
  ],

  // 관악
  'gk-gwanak-01': [
    83.20805746408496,
    69.41632996632997
  ],
  'gk-gwanak-02': [
    82.82467207995003,
    59.73472823472822
  ],
  'gk-gwanak-03': [
    82.73173016864459,
    50.49025974025974
  ],
  'gk-gwanak-04': [
    82.62476577139289,
    41.11673881673882
  ],
  'gk-gwanak-05': [
    82.57086196127422,
    31.735185185185188
  ],
  'gk-gwanak-06': [
    82.48728919425359,
    22.033189033189032
  ],
  'gk-gwanak-07': [
    32.8476577139288,
    88.40127465127466
  ],
  'gk-gwanak-08': [
    32.763335415365404,
    78.4622174122174
  ],
  'gk-gwanak-09': [
    51.83460337289195,
    60.124362674362686
  ],
  'gk-gwanak-10': [
    32.623360399750155,
    60.124290524290515
  ],
  'gk-gwanak-11': [
    55.46873828856965,
    47.3605339105339
  ],
  'gk-gwanak-12': [
    42.594534665833855,
    47.3605339105339
  ],
  'gk-gwanak-13': [
    29.92217364147408,
    47.3605339105339
  ],
  'gk-gwanak-14': [
    51.06305434103685,
    30.139514189514188
  ],
  'gk-gwanak-15': [
    51.0927232979388,
    20.127224627224628
  ],
  'gk-gwanak-16': [
    33.533885071830106,
    14.461832611832612
  ],
  'gk-gwanak-17': [
    4.092067457838851,
    46.646777296777294
  ],
  'gk-gwanak-18': [
    4.007807620237353,
    37.05964405964406
  ],
  'gk-gwanak-19': [
    4.024890693316678,
    27.604810004810005
  ],
  'gk-gwanak-20': [
    3.7872267332916927,
    17.91188071188071
  ]
};
