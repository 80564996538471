<ion-header translucent>
  <ion-toolbar [class.hidden]="(step === 'ongoing')">
    <ion-buttons slot="start" *ngIf="(step === 'order')">
      <ion-back-button [defaultHref]="currentSite"></ion-back-button>
    </ion-buttons>
    <ion-title>{{step === 'finish' ? (kiccResult.res_cd === '0000' ? '주문완료' : '주문실패')  : '주문페이지' }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen class="order-form-container" [class.finish]="step === 'finish'">
  <form [formGroup]="orderForm" [class.hidden]="!(step === 'order')">
    <ion-item *ngIf="(unifiedOrder.address_key.length === 0) && (deliveryType === 'DELIVERY')" lines="none" class="delivery-input">
      <h3>배달정보</h3>
      <!-- <h3 *ngIf="deliveryType === 'TAKEOUT'" class="takeout">{{ siteKR }}</h3> -->
    </ion-item>
    <ion-list *ngIf="deliveryType === 'DELIVERY'" lines="none" [class.top-padding]="userStateLoaded && (orderForm.get('address_detail').value.length > 0)" class="address-modal-button-container">
      <ion-button *ngIf="!userStateLoaded" class="address-modal-button" color="light" expand="block" (click)="presentAddressModal()">
        <h3>주소 찾기</h3>
        <ion-icon slot="end" name="search-outline"></ion-icon>
      </ion-button>
      <div *ngIf="unifiedOrder.address_key.length > 0">
        <ion-item class="address-key">
          <ion-label text-wrap>
            <h2>{{ unifiedOrder.address_key }}</h2>
            <h3>{{ unifiedOrder.address_road }}</h3>
          </ion-label>
        </ion-item>
        <div class="required-field">
          <ion-item class="ion-no-margin ion-no-padding" lines="inset" mode="md">
            <ion-label position="stacked">상세주소 <ion-text color="danger">*</ion-text></ion-label>
            <ion-input required type="text" formControlName="address_detail"></ion-input>
          </ion-item>
        </div>
      </div>
    </ion-list>

    <!-- <div class="divider"></div> -->

    <!-- <ion-item lines="none" class="user-input">
      <h3>주문자 정보</h3>
    </ion-item> -->
    <div class="required-field">
      <ion-list class="ion-no-margin ion-no-padding" mode="md">
        <ion-item class="ion-no-margin ion-no-padding with-button" lines="inset">
          <ion-label position="stacked">전화번호 <ion-text color="danger">*</ion-text></ion-label>
          <ion-input required type="tel" formControlName="userTel"></ion-input>
        </ion-item>
        <ion-icon *ngIf="confirmAuth" name="checkmark-circle-outline" color="success"></ion-icon>
        <ion-button *ngIf="!confirmAuth" [color]="sessionId ? 'light' : 'primary'" class="right-button" [disabled]="orderForm.get('userTel').invalid" (click)="sendAuthSMS()">
          {{ sessionId ? '재전송' : '인증문자 전송' }}
        </ion-button>
        <div class="validation"></div>

        <div *ngIf="sessionId && !confirmAuth">
          <ion-item class="ion-no-margin ion-no-padding with-button" lines="inset">
            <ion-label position="stacked">인증번호 입력 <ion-text color="danger">*</ion-text></ion-label>
            <ion-input required type="tel" formControlName="authCode" [disabled]="orderForm.get('userTel').invalid" (keydown.enter)="confirmSMSCode()"></ion-input>
          </ion-item>
          <ion-button class="right-button" (click)="confirmSMSCode()">인증번호 확인</ion-button>
          <div class="validation"></div>
        </div>
      </ion-list>

      <ion-button *ngIf="userStateLoaded && confirmAuth" class="ion-no-margin" color="light" expand="block" (click)="changeUserState()">
        <h3>{{ (deliveryType === 'DELIVERY') ? '주소 및 전화번호 수정하기' : '전화번호 수정하기' }}</h3>
      </ion-button>
    </div>
    <div class="divider"></div>

    <!-- <div *ngIf="availableLocalStorage && !userStateLoaded" class="option-field storage">
      <ion-item class="ion-no-margin ion-no-padding with-button" lines="none" mode="md">
        <ion-label>배달정보 저장</ion-label>
        <ion-checkbox slot="start" [checked]="useLocalStorage"
        (ionChange)="onCheckboxChange($event)"></ion-checkbox>
      </ion-item>
    </div>
    <div *ngIf="!userStateLoaded" class="divider"></div> -->

    <div class="option-field">
      <ion-list class="ion-no-margin ion-no-padding" mode="md">
        <ion-item class="ion-no-margin ion-no-padding" lines="inset">
          <ion-label position="stacked">요청사항</ion-label>
          <ion-input type="text" formControlName="orderMsg" placeholder="예) 고수 빼 주세요."></ion-input>
        </ion-item>
        <ion-item class="ion-no-margin ion-no-padding disposable-check-box" lines="none">
          <ion-checkbox slot="start" [checked]="noDisposableItem"
            (ionChange)="checkDisposableItem($event)"></ion-checkbox>
          <ion-label>일회용 수저, 포크는 주지 마세요.</ion-label>
        </ion-item>
      </ion-list>
    </div>

    <div class="divider"></div>

    <div class="payment-form">
      <div class="payment-item half">
        <p>결제금액</p>
        <p>{{ totalAmount | number}}원</p>
      </div>
      <div class="payment-item">
        <p><b>신용카드 선불결제만 가능합니다.</b></p>
      </div>
      <!-- <ion-item lines="none" class="ion-no-margin ion-no-padding">
        <ion-label>결제수단</ion-label>
        <ion-select [value]="spPayType" interface="action-sheet" name="spPayType" (ionChange)="handleSelect($event)">
          <ion-select-option value="11" selected>신용카드</ion-select-option>
          <ion-select-option value="01">후불카드</ion-select-option>
          <ion-select-option value="02">후불현금</ion-select-option>
          <ion-select-option value="21">계좌이체</ion-select-option>
          <ion-select-option value="22">가상계좌</ion-select-option>
          <ion-select-option value="31">휴대폰</ion-select-option>
          <ion-select-option value="50">선불결제</ion-select-option>
          <ion-select-option value="60">간편결제</ion-select-option>
          <ion-select-option value="81">배치인증</ion-select-option>
        </ion-select>
      </ion-item> -->
    </div>
    <div class="divider"></div>

    <div class="info">
      <ul>
        <li>결제 후 접수가 되면 예상 조리 시간을 문자로 알려드립니다.</li>
        <li>{{ room.siteName }} {{ unifiedOrder.room.split('-')[2] | number }}호에 오셔서 받아 가세요.</li>
        <li>문의사항은 <a [href]="room.telNo ? 'tel:' + room.telNo : 'tel:1522-6385'">{{ room.telNo ? room.telNo : '1522-6385 (평일: 오전 9시 ~ 오후 6시)' }}</a>를 이용해 주세요.</li>
      </ul>  
    </div>
  </form>

  <!-- 결제승인결과 -->
  <app-order-result *ngIf="(step === 'finish')" [kiccResult]="kiccResult" [unifiedOrder]="unifiedOrder"></app-order-result>
  
  <iframe
    #Iframe
    [class.hidden]="!(step === 'ongoing')"
    [src]="iframeUrl">
  </iframe>
</ion-content>

<ion-footer *ngIf="(step === 'order')">
  <div class="validation">
    <span *ngIf="orderForm.invalid || !confirmAuth"><b>전화번호 인증이 필요합니다.</b></span>
  </div>
  <ion-button expand="block" 
    *ngIf="totalQty > 0" 
    [disabled]="orderForm.invalid || !confirmAuth" 
    (click)="order()">
    <b class="price">{{ totalAmount | number }}원</b> 결제하기
  </ion-button>
</ion-footer>

<ion-footer *ngIf="(step === 'finish')">
  <ion-button expand="block" (click)="goHome()">
    확인
  </ion-button>
</ion-footer>