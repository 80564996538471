/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { UnifiedOrderFood, UnifiedOrderDeliveryType } from '../../schema/1/schema-common';

import { ActionSheetOrderService } from '../../shared/action-sheet-order/action-sheet-order.service';
import { ShoppingCartService } from './shopping-cart.service';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit, OnDestroy {
  currentSite: string;
  unifiedOrderFoods: UnifiedOrderFood[] = [];

  // UI 표시용
  totalQty = 0;
  totalAmount = 0;
  shopNo: string;
  shopName: string;

  private shoppingCartSubscription: Subscription = null;
  private unifiedMenuSubscription: Subscription = null;

  constructor(
    private shoppingCartService: ShoppingCartService,
    private actionSheetOrderService: ActionSheetOrderService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    // defaultHref
    this.currentSite = this.route.snapshot.paramMap.get('site');
    // '메뉴 추가'시 해당 Shop으로 Route 하기 위함.
    const shopNo = this.shoppingCartService.shopNo;

    this.shopNo = shopNo;
    this.shopName = this.shoppingCartService.shopDetail.shopName;
    this.observeShoppingCart();
  }

  ngOnDestroy() {
    if (this.shoppingCartSubscription) {
      this.shoppingCartSubscription.unsubscribe();
    }
    if (this.unifiedMenuSubscription) {
      this.unifiedMenuSubscription.unsubscribe();
    }
    if (this.actionSheetOrderService.actionSheet) {
      this.actionSheetOrderService.dissmissActionSheet();
    }
  }

  observeShoppingCart() {
    this.shoppingCartSubscription = this.shoppingCartService.latestUnifiedOrderFoodsSubject.subscribe(unifiedOrderFoods => {
      this.unifiedOrderFoods = unifiedOrderFoods;
      this.totalAmount = this.unifiedOrderFoods.reduce((sum, food) => sum + food.foodOrdPrice, 0);
      this.totalQty = this.unifiedOrderFoods.reduce((sum, food) => sum + food.foodQty, 0);
    });
  }

  removeFood(index: number) {
    this.shoppingCartService.removeFood(index);
  }

  increaseFoodQty(index: number) {
    this.shoppingCartService.increaseFoodQty(index);
  }

  decreaseFoodQty(index: number) {
    this.shoppingCartService.decreaseFoodQty(index);
  }

  goBackToShop() {
    if (this.shopNo) {
      this.router.navigate([`../${this.shopNo}`], { relativeTo: this.route });
    } else {
      this.router.navigate([`../`], { relativeTo: this.route });
    }
  }

  // 배달, 픽업 선택
  // presentOrderActionSheet() {
  //   this.actionSheetOrderService.presentActionSheet(
  //     () => this.goOrder('TAKEOUT'),
  //     () => this.goOrder('DELIVERY')
  //   );
  // }

  goOrder(deliveryType: UnifiedOrderDeliveryType) {
    const currentSite = this.route.snapshot.paramMap.get('site');
    this.router.navigate([currentSite, 'order'], { queryParams: { deliveryType } });
  }
}
