/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
  UrlTree,
} from '@angular/router';

import { UnifiedMenuService } from './core/1/unified-menu.service';

/**
 * 개별 페이지를 표시하기 전에 준비해야 할 것이 있으면 기다리도록 한다.
 * - unifiedMenu
 */
@Injectable({
  providedIn: 'root'
})
export class MenuGuardService implements CanActivate {
  constructor(
    private router: Router,
    private unifiedMenuService: UnifiedMenuService
  ) { }

  promiseForInit(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const site = route.paramMap.get('site');
    const shopNo = route.paramMap.get('shop');
    const defaultUrl = `${site}/${shopNo}`;
    const indexes = route.paramMap.get('food').split('-');
    const parsedUrl = this.router.parseUrl(defaultUrl);

    const unifiedMenu = this.unifiedMenuService.unifiedMenuForSite;

    return new Promise<boolean | UrlTree>((resolve) => {
      if (unifiedMenu && unifiedMenu.length > 0) {
        const shop = unifiedMenu.find(menu => menu.shopNo === shopNo);
        if (shop && shop.menus[Number(indexes[0])].foods[Number(indexes[1])]) {
          resolve(true);
        } else {
          resolve(parsedUrl);
        }
      } else {
        resolve(parsedUrl);
      }
    });
  }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.promiseForInit(route);
  }
}
