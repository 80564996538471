/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MenuFormComponent } from './menu-form/menu-form.component';
import { OrderFormComponent } from './order-form/order-form.component';
import { OrderResultComponent } from './order-result/order-result.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  declarations: [
    MenuFormComponent,
    OrderFormComponent,
    OrderResultComponent
  ]
})
export class FormModule { }
