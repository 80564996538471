/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';

import { RoomDoc, RoomDocs } from '../../schema/3/schema';

import { UserService } from '../2/user.service';

const collectionPath = 'room';

@Injectable({
  providedIn: 'root'
})
export class RoomService {
  public rooms: RoomDocs = {};
  public latestSubject = new BehaviorSubject<RoomDocs>({});

  constructor(
    private db: AngularFirestore,
    private userService: UserService
  ) {
  }

  observe() {
    const organization = this.userService.organization;
    const queryFn: QueryFn = ref => ref.where('organization', '==', organization);

    const collection = this.db.collection<RoomDoc>(collectionPath, queryFn);
    collection.valueChanges().subscribe(docs => {
      const roomDocs = {};

      for (const doc of docs) {
        roomDocs[doc._id] = doc;
      }

      this.rooms = roomDocs;

      this.latestSubject.next(this.rooms);
    });
  }
}
