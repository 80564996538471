/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from './auth-guard.service';
import { SitesComponent } from './pages/sites/sites.component';
import { ShopComponent } from './pages/shop/shop.component';
import { MenuFormComponent } from './form/menu-form/menu-form.component';
import { OrderFormComponent } from './form/order-form/order-form.component';
import { ShoppingCartComponent } from './pages/shopping-cart/shopping-cart.component';
import { MenuGuardService } from './menu-guard.service';
import { HomeComponent } from './pages/home/home.component';
import { InitGuardService } from './init-guard.service';
import { OrderGuardService } from './order-guard.service';
import { ShoppingCartGuardService } from './shopping-cart-guard.service';
import { MyOrderComponent } from './pages/my-order/my-order.component';
import { ReceiptComponent } from './pages/receipt/receipt.component';
import { ReceiptGuardService } from './pages/receipt/receipt-guard.service';
import { ZoningMapComponent } from './pages/zoning-map/zoning-map.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [InitGuardService],
    canActivateChild: [AuthGuardService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: HomeComponent
      },
      {
        path: 'page-not-found',
        pathMatch: 'full',
        component: PageNotFoundComponent
      },
      {
        path: 'my-order',
        pathMatch: 'full',
        component: MyOrderComponent
      },
      {
        path: 'my-order/:order',
        pathMatch: 'full',
        component: ReceiptComponent,
        canActivate: [ReceiptGuardService]
      },
      {
        path: 'map/:site',
        pathMatch: 'full',
        component: ZoningMapComponent
      },
      {
        path: ':site/order',
        pathMatch: 'full',
        component: OrderFormComponent,
        canActivate: [OrderGuardService]
      },
      {
        path: ':site/cart',
        pathMatch: 'full',
        component: ShoppingCartComponent,
        canActivate: [ShoppingCartGuardService]
      },
      {
        path: ':site/:shop/:food',
        pathMatch: 'full',
        component: MenuFormComponent,
        canActivate: [MenuGuardService],
      },
      {
        path: ':site/:shop',
        pathMatch: 'full',
        component: ShopComponent
      },
      {
        path: ':site',
        pathMatch: 'full',
        component: SitesComponent
      },
    ]
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
