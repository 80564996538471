<div class="order-result-container" *ngIf="kiccResult && unifiedOrder">
  <div class="order-result" *ngIf="kiccResult.res_cd === '0000'">
    <div class="order-head result">
      <p>잠시 후 접수가 완료되면 예상 조리 시간을<br><ion-icon name="mail-outline"></ion-icon><b>문자로</b> 알려드립니다.</p>
    </div>
    <div class="divider"></div>
  </div>

  <div class="order-result" *ngIf="kiccResult.res_cd !== '0000'">
    <div class="order-head result">
      <p>결제 실패 사유 : <b>{{ kiccResult.res_msg }}</b></p>
    </div>
    <div class="divider"></div>
  </div>

  <div class="site-name">
    <ion-item lines="none">
      <ion-label><span>{{ site.siteName }} {{ unifiedOrder.room.split('-')[2] | number }}호</span></ion-label>
      <ion-note (click)="openDestMap()" slot="end" color="success">위치 보기</ion-note>
    </ion-item>
  </div>
  <ion-list lines="none" class="order-food-list">
    <h4 class="shop-name">{{ unifiedOrder.shopName }}</h4>
    <ion-item-group *ngFor="let unifiedOrderFood of unifiedOrder.foods; let idx = index">
      <ion-item class="food-name">
        <ion-label>{{ unifiedOrderFood.foodName + (unifiedOrderFood.foodOpts[0].optName ? ('_' + unifiedOrderFood.foodOpts[0].optName) : '') }}</ion-label>
        <ion-note color="dark" slot="end">{{ unifiedOrderFood.foodOpts[0].optPrice * unifiedOrderFood.foodOpts[0].optQty * unifiedOrderFood.foodQty | number}}원</ion-note>
      </ion-item>

      <ion-item-group>
        <ion-item *ngFor="let foodOpt of unifiedOrderFood.foodOpts.slice(1)" class="option-name">
          <ion-label>+ {{ foodOpt.optName }}({{ foodOpt.optPrice * foodOpt.optQty * unifiedOrderFood.foodQty | number }})</ion-label>
        </ion-item>
        <div class="bottom-line"></div>
      </ion-item-group>
    </ion-item-group>

    <div class="price-list">
      <!-- <div class="price-item" *ngIf="unifiedOrder.deliveryType === 'DELIVERY'">
        <p>배달요금</p>
        <p>{{ unifiedOrder.deliveryTip | number}}원</p>
      </div> -->
      <div class="price-item">
        <p>총 주문금액</p>
        <p>{{ unifiedOrder.orderAmount | number}}원</p>
      </div>
      <div class="price-item total">
        <p>총 결제금액</p>
        <p *ngIf="kiccResult.res_cd === '0000'">{{ kiccResult.amount | number}}원</p>
        <p *ngIf="kiccResult.res_cd !== '0000'">0원</p>
      </div>
      <div class="price-item total" *ngIf="kiccResult.res_cd === '0000'">
        <p>결제방법</p>
        <p>{{ payType }}</p>
      </div>
    </div>
    <div class="divider"></div>

    <div class="order-message" *ngIf="unifiedOrder.orderMsg.length > 0">
      <p>요청사항</p>
      <p>{{ unifiedOrder.orderMsg }}</p>
    </div>
    <div class="divider" *ngIf="unifiedOrder.orderMsg.length > 0"></div>
  </ion-list>

  <h3 *ngIf="kiccResult.res_cd === '0000'">{{ unifiedOrder.deliveryType === 'DELIVERY' ? unifiedOrder.address_key : '테이크아웃' }}</h3>
  <div class="order-head" *ngIf="kiccResult.res_cd === '0000'">
    <p>전화번호 : {{ userTel }}</p>
    <p>주문일시 : {{ unifiedOrder.orderDate | date:'yyyy-MM-dd HH:mm:ss'}}</p>
  </div>
</div>
