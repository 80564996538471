/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import {
  CanActivate, Route,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild, CanLoad
} from '@angular/router';

import { AuthService } from './core/2/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private authService: AuthService,
  ) { }

  /**
   * promise는 onetime이기 때문에 take operator를 이용해서 1개만 취한다.
   * 처음에는 서버에서 받아온 값이 아니기 때문에 filter를 이용해서 서버의 값만 넘긴다.
   */
  promiseForLoggedIn() {
    return new Promise<boolean>((resolve, reject) => {
      resolve(true);

      this.authService.observeLoggedIn().pipe(
        filter(value => {
          return value === true || value === false;
        }),
        take(1)
      ).subscribe(value => {
        resolve(value);
      }, err => {
        reject(err);
      });
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log(`canActivate: '${route.url}'`);

    return this.promiseForLoggedIn();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // console.log(`canActivateChild: '${childRoute.url}'`);

    return this.promiseForLoggedIn();
  }

  canLoad(route: Route) {
    // console.log(`canLoad: '${route.path}'`);

    return this.promiseForLoggedIn();
  }
}
