/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { environment } from '../../../environments/environment';

let urlPrefix = `https://us-central1-toe-prod.cloudfunctions.net/sms`;

/**
 * 인증용 SMS를 발신한다
 * @param tel : 010-1234-5678 또는 01012345678
 */
export async function getRequestAuth(tel: string) {
  const requestInit: RequestInit = {
    method: 'GET',
    cache: 'no-store',
    headers: {
      'Content-Type': 'text/plain'
    }
  };

  if (environment.production === false) {
    urlPrefix = `https://us-central1-toe-dev-2019.cloudfunctions.net/sms`;
  }

  return fetch(`${urlPrefix}/req-auth?tel=${tel}`, requestInit);
}


/**
 * 사용자가 입력한 authCode의 유효성을 검사한다.
 */
export async function getConfirmAuth(sessionId: string, authCode: string) {
  const requestInit: RequestInit = {
    method: 'GET',
    cache: 'no-store',
    headers: {
      'Content-Type': 'text/plain'
    }
  };

  if (environment.production === false) {
    urlPrefix = `https://us-central1-toe-dev-2019.cloudfunctions.net/sms`;
  }

  return fetch(`${urlPrefix}/confirm-auth?session=${sessionId}&authCode=${authCode}`, requestInit);
}
