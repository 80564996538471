/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { ModalController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';

import { ViewModelService } from '../../../core/5/view-model.service';

@Component({
  selector: 'app-category-modal',
  templateUrl: './category-modal.component.html',
  styleUrls: ['./category-modal.component.scss']
})
export class CategoryModalComponent implements OnInit {
  @Input() allCategories: string[];
  @Input() categories: string[];
  @Input() setCategories: (categories: string[]) => void;
  isEmpty = false;
  isAllChecked = true;
  checkBoxList: { value: string, isChecked: boolean, amount: number }[] = [];
  totalAmount: number;

  constructor(
    private modalController: ModalController,
    private viewModelService: ViewModelService
  ) { }

  ngOnInit() {
    this.initCheckBoxList();
  }

  initCheckBoxList() {
    const { menuViewModel } = this.viewModelService.viewModelForSite[this.viewModelService.currentSite];
    this.checkBoxList = Array(this.allCategories.length);
    this.allCategories.map((cg, index) => (
      this.checkBoxList[index] = {
        value: cg,
        isChecked: this.categories.indexOf(cg) > -1,
        amount: menuViewModel.filter(item => item.category === cg).length
      }
    ));
    this.isAllChecked = (this.checkBoxList.filter(i => i.isChecked).length === this.allCategories.length);
    this.totalAmount = this.checkBoxList.reduce((sum, item) => sum + item.amount, 0);
  }

  handleCheckBox() {
    this.isEmpty = !(this.checkBoxList.filter(i => i.isChecked).length > 0);
    this.isAllChecked = (this.checkBoxList.filter(i => i.isChecked).length === this.allCategories.length);
  }

  toggleSelectAll(value: boolean) {
    this.checkBoxList.forEach(item => item.isChecked = value);
  }

  selectCategories() {
    const selectedCategories = this.checkBoxList.filter(i => i.isChecked).map(i => i.value);
    this.dismissModal();
    this.setCategories(selectedCategories);
  }

  async dismissModal() {
    await this.modalController.dismiss();
  }
}
