/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { cloneDeep } from 'lodash';

import { Subscription, timer } from 'rxjs';
import { filter, debounce, skip, tap } from 'rxjs/operators';
import { IonRange, IonInfiniteScroll } from '@ionic/angular';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { Component, OnInit, Input, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';


import { MenuViewModel, SortKey as SortOption } from '../../schema/1/schema-view-model';

import { sortOptionMap } from '../../core/1/string-map';
import { LogService } from '../../core/3/log.service';
import { ViewModelService } from '../../core/5/view-model.service';

import { LocalStorageService } from '../../shared/local-storage/local-storage.service';
import { ModalService } from '../../shared/modal/modal.service';
import { LoadingService } from '../../shared/loading/loading.service';

@Component({
  selector: 'app-grid-menu',
  templateUrl: './grid-menu.component.html',
  styleUrls: ['./grid-menu.component.scss']
})
export class GridMenuComponent implements OnInit, OnDestroy {
  @ViewChild('rangeRef', { static: true }) rangeRef: IonRange;
  @ViewChild(IonInfiniteScroll, { static: true }) infiniteScroll: IonInfiniteScroll;
  @Input() showUp: boolean;
  @Input() site: string;
  @Output() scrollToTop = new EventEmitter<void>();
  menuViewModel: MenuViewModel[] = [];

  imageMenuModel: { row: MenuViewModel[]; }[];
  textMenuModel: { row: MenuViewModel[]; }[];

  imageMenuViewModel: { row: MenuViewModel[]; }[];
  textMenuViewModel: { row: MenuViewModel[]; }[];
  currentImageMenuLength = 0;
  currentTextMenuLength = 0;

  allCategories: string[] = [];
  selectedCategories: string[] = [];
  summarizedCategories: string;

  maxPrice = 0;
  priceRange = { lower: 0, upper: 0 };
  displayPriceRange = { lower: 0, upper: 0 };
  searchKey: string;
  sortOption: SortOption = 'shuffle';
  sortOptionKR: string = sortOptionMap[this.sortOption];
  sortKeys: number[] = [];
  rowSize = 1;

  dummyGrid = [];
  isLoading = true;
  showOptionPanel = false;
  displayNone = true;
  isCategoryAll = false;
  totalItemLength: number;
  filteredItemLength: number;

  prevSite: string;
  params = { cg: '', sort: '', row: '', range: '' };

  sortForm = new FormGroup({
    sort: new FormControl('shuffle'),
  });

  private routeSubscription: Subscription;
  private queryParamSubscription: Subscription;
  private viewModelSubscription: Subscription;
  private rangeEventSubscription: Subscription;

  constructor(
    private viewModelService: ViewModelService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService,
    private logService: LogService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit() {
    this.loadLocalRowSize();
    this.observeRoute();
    this.subscribeAllExceptRoute();
    this.dummyGrid = this.setPlaceHolder(5, this.rowSize);
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
      this.routeSubscription = undefined;
    }
    this.unsubscribeAllExceptRoute();
  }

  private subscribeAllExceptRoute() {
    if (!this.queryParamSubscription) {
      this.observeQueryParam();
    }
    if (!this.viewModelSubscription) {
      this.observeViewModel();
    }
    if (!this.rangeEventSubscription) {
      this.observeRangeEvent();
    }
  }

  private unsubscribeAllExceptRoute() {
    if (this.queryParamSubscription) {
      this.queryParamSubscription.unsubscribe();
      this.queryParamSubscription = undefined;
    }
    if (this.viewModelSubscription) {
      this.viewModelSubscription.unsubscribe();
      this.viewModelSubscription = undefined;
    }
    if (this.rangeEventSubscription) {
      this.rangeEventSubscription.unsubscribe();
      this.rangeEventSubscription = undefined;
    }
  }

  private loadLocalRowSize() {
    if (!this.localStorageService.checkAvailable()) {
      return;
    }

    if (this.localStorageService.isExist('rowSize')) {
      try {
        const rowSize = this.localStorageService.getValue('rowSize') as string;
        this.rowSize = Number(rowSize);
      } catch (error) {
        this.logService.info(`loadLocalUserState : ${error}`);
      }
    }
  }

  private saveLocalRowSize(rowSize: string) {
    if (!this.localStorageService.checkAvailable()) {
      return;
    }

    try {
      this.localStorageService.setItem('rowSize', rowSize);
    } catch (error) {
      this.logService.error(`saveLocalRowSize : ${error}`);
    }
  }

  private observeRoute() {
    this.routeSubscription = this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const { urlAfterRedirects } = event;
        const urls = urlAfterRedirects.split('/');
        if (urls.length > 2) {
          this.unsubscribeAllExceptRoute();
        } else {
          this.subscribeAllExceptRoute();
        }
      });
  }

  private observeQueryParam() {
    this.queryParamSubscription = this.route.queryParamMap.subscribe(params => {
      this.params = this.getParams(params);

      // 카테고리
      const categories = params.get('cg');
      if (categories && JSON.stringify(categories) !== JSON.stringify(this.selectedCategories)) {
        this.selectedCategories = categories.split(',');
        this.sumupCategory(this.selectedCategories, this.allCategories);
      }

      // 정렬
      const sortKey = params.get('sort') as SortOption;
      if (sortKey && sortKey !== this.sortOption) {
        this.sortOption = sortKey;
        this.sortOptionKR = sortOptionMap[sortKey];
      }

      // Row Size
      const rowSize = Number(params.get('row'));
      if (rowSize && rowSize !== this.rowSize) {
        this.rowSize = rowSize;
        this.saveLocalRowSize(String(rowSize));
      }

      // Price Range
      const range = params.get('range');
      if (range) {
        const splitRange = range.split('-').map(i => Number(i));
        const priceRange = { lower: splitRange[0], upper: splitRange[1] };
        if (!(priceRange.lower === this.priceRange.lower && priceRange.upper === this.priceRange.upper)) {
          this.priceRange = priceRange;
          this.displayPriceRange = this.priceRange;
        }
      }

      this.updateView();
    });
  }

  private sumupCategory(categories: string[], allCategories: string[]) {
    if (categories === undefined || allCategories.length === categories.length) {
      this.summarizedCategories = '전체';
    } else if (categories.length > 2) {
      this.summarizedCategories = `${categories[0]} 외 ${categories.length - 1}개`;
    } else {
      this.summarizedCategories = categories.join(',');
    }
  }

  private observeViewModel() {
    this.viewModelSubscription = this.viewModelService.latestShopViewModelForSiteSubject
      .pipe(
        tap(viewModel => this.isLoading = viewModel === undefined),
        filter(viewModel => viewModel !== undefined && viewModel[this.viewModelService.currentSite] !== undefined)
      )
      .subscribe(viewModel => {
        const { menuViewModel } = viewModel[this.viewModelService.currentSite];
        const prices = menuViewModel.map(item => item.price);
        const maxPrice = prices.length > 0 ? Math.max(...prices) : 0;

        this.menuViewModel = this.setSortKeyForShuffle(this.menuViewModel, menuViewModel);
        this.totalItemLength = this.menuViewModel.length;
        // 카테고리만 따로 추출 및 가나다순 정렬
        const allCategories = [...new Set(menuViewModel
          .filter(item => item.category)
          .map(item => item.category))
        ].sort((a, b) => a < b ? -1 : a > b ? 1 : 0);

        if (this.selectedCategories.length === 0) {
          // 최초 진입에 의한 카테고리 초기화
          this.selectedCategories = allCategories;
          this.sumupCategory(this.selectedCategories, allCategories);
        } else if (this.prevSite && this.prevSite !== this.viewModelService.currentSite) {
          // 사이트 변경에 의한 카테고리 & priceRange 초기화
          this.selectedCategories = allCategories;
          this.sumupCategory(this.selectedCategories, allCategories);
          this.priceRange.upper = maxPrice;
        }

        if (this.maxPrice !== maxPrice) {
          this.maxPrice = maxPrice;
        }

        // 메뉴가격이 없거나 초기화가 안된 경우
        if (this.priceRange.upper === 0) {
          this.priceRange.upper = maxPrice;
        }

        this.displayPriceRange = this.priceRange;
        this.prevSite = this.viewModelService.currentSite;

        this.isLoading = this.site !== this.viewModelService.currentSite;

        // 전체 카테고리 수가 -줄어든 경우
        if (this.allCategories.length > allCategories.length) {
          const selectedCategories = this.selectedCategories.filter(cg => allCategories.indexOf(cg) > -1);
          if (selectedCategories.length < allCategories.length) {
            this.allCategories = allCategories;
            return this.setCategories(selectedCategories);
          } else {
            this.selectedCategories = selectedCategories;
          }
        }

        // 전체 카테고리 수가 +증가한 경우
        if (this.allCategories.length < allCategories.length) {
          if (this.selectedCategories.length === this.allCategories.length) {
            this.selectedCategories = allCategories;
          }
        }
        this.allCategories = allCategories;
        this.updateView();
      });
  }

  private setSortKeyForShuffle(menuViewModel: MenuViewModel[], newMenuViewModel?: MenuViewModel[]) {
    const menuViewModelForSort = newMenuViewModel ? newMenuViewModel : menuViewModel;
    this.sortKeys = newMenuViewModel ? this.sortKeys : [];

    if (this.sortKeys.length < 1) {
      // 초기값 할당
      this.sortKeys = [...Array(menuViewModelForSort.length).keys()].sort(() => 0.5 - Math.random());
    } else if (this.sortKeys.length < menuViewModelForSort.length) {
      // 목록이 추가된 경우
      const additionalSortKeys = [...Array(menuViewModelForSort.length).keys()].sort(() => 0.5 - Math.random()).slice(this.sortKeys.length);
      this.sortKeys = [...this.sortKeys, ...additionalSortKeys];
    } else if (this.sortKeys.length > menuViewModelForSort.length) {
      // 목록이 줄어든 경우
      this.sortKeys = this.sortKeys.slice(menuViewModelForSort.length);
    }

    // init sortKey
    if (menuViewModel.length === 0) {
      return menuViewModelForSort.map((item, index) => ({
        ...item,
        sortKey: this.sortKeys[index]
      }));
    }

    if (newMenuViewModel) {
      return menuViewModelForSort
        .map((item, index) => {
          const sortedItem = menuViewModel.find(i => i.foodName === item.foodName);
          return sortedItem
            ? { ...item, sortKey: sortedItem.sortKey }
            : { ...item, sortKey: this.sortKeys[index] };
        });
    } else {
      return menuViewModel.map((item, index) => ({
        ...item,
        sortKey: this.sortKeys[index]
      }));
    }
  }

  private setPlaceHolder(rows: number = 4, rowSize: number) {
    return [...Array(rows).keys()].map(() => {
      return [...Array(rowSize).keys()];
    });
  }

  async presentCategoryModal() {
    try {
      await this.loadingService.presentLoading();
      await this.modalService.presentCategoryModal(
        this.allCategories,
        this.selectedCategories,
        (categories: string[]) => this.setCategories(categories)
      );
    } catch (error) {
      this.logService.withToastrCatch(error, `presentCategoryModal에서 예외발생 `);
    }

    this.loadingService.dismissLoading();
  }

  private getParams(paramMap: ParamMap) {
    const params = this.params;
    Object.keys(this.params).forEach(key => {
      params[key] = paramMap.get(key) ? paramMap.get(key) : undefined;
    });
    return params;
  }


  /********************************************************************
   * [EventHandler]
   * observeRangeEvent: 가격 범위 설정 이벤트 구독
   * setCategories: 카테고리 설정
   * selectRowSize: 메뉴 view의 row 사이즈 조정
   * sortBy: 정렬
   ********************************************************************/
  observeRangeEvent() {
    this.rangeEventSubscription = this.rangeRef.ionChange
      .pipe(
        skip(1),
        tap(event => this.displayPriceRange = event.detail.value),
        debounce(() => timer(500)),
        filter(event => (this.priceRange.lower !== event.detail.value.lower) || (this.priceRange.upper !== event.detail.value.upper))
      )
      .subscribe(event => {
        const { value } = event.detail;
        this.router.navigate([`/${this.site}`], {
          queryParams: {
            ...this.params,
            range: `${value.lower}-${value.upper}`
          }
        });
        this.initInfiniteScrollLength();
      })
      ;
  }

  setCategories(categories: string[]) {
    const isChanged = JSON.stringify(this.selectedCategories) !== JSON.stringify(categories);
    // 변화가 있는 경우에만 업데이트한다.
    if (isChanged) {
      this.router.navigate([`/${this.site}`], {
        queryParams: {
          ...this.params,
          cg: categories.join(',')
        }
      });
      this.initInfiniteScrollLength();
    }
  }

  selectRowSize(value: number) {
    if (this.rowSize !== value) {
      this.router.navigate([`/${this.site}`], {
        queryParams: {
          ...this.params,
          row: value
        }
      });
    }
  }

  selectSortOption(value: SortOption) {
    if (this.sortOption !== value) {
      this.router.navigate([`/${this.site}`], {
        queryParams: {
          ...this.params,
          sort: value
        }
      });
    }

    if (value === 'shuffle') {
      this.menuViewModel = this.setSortKeyForShuffle(this.menuViewModel);
      this.updateView();
    }
  }

  async initFilter() {
    await this.loadingService.presentLoading();

    // 값 초기화
    this.selectedCategories = this.allCategories;
    this.sumupCategory(this.selectedCategories, this.allCategories);
    this.sortOption = 'shuffle';
    this.searchKey = undefined;
    this.priceRange = { lower: 0, upper: this.maxPrice };
    this.displayPriceRange = this.priceRange;

    // URL을 변경할 필요가 없는경우 View만 업데이트한다.
    const { cg, sort, row, range } = this.params;
    (cg || sort || row || range)
      ? this.router.navigate([`/${this.site}`])
      : this.updateView();
    this.loadingService.dismissLoading();
    this.initInfiniteScrollLength();
  }

  /********************************************************************
   * 모든 설정값을 반영하여 최종 ViewModel을 만든다.
   * sortedList: 정렬
   * sliceList: row Size에 맞춰 col 생성
   ********************************************************************/
  private updateView() {
    const sortedList = this.sortedList(this.menuViewModel);
    let filteredList = sortedList.filter(item => this.selectedCategories.indexOf(item.category) > -1);

    // 가격 범위
    const range = this.priceRange;
    filteredList = filteredList.filter(item => item.price >= range.lower && item.price <= range.upper);

    // 검색어 반영
    if (this.searchKey && this.searchKey.length > 0) {
      filteredList = filteredList.filter(item => item.foodName.indexOf(this.searchKey) > -1);
    }

    const imageMenuList = filteredList.filter(item => item.imageUrl);
    const textMenuList = filteredList.filter(item => !item.imageUrl);
    this.imageMenuModel = this.sliceList(imageMenuList, this.rowSize);
    this.textMenuModel = this.sliceList(textMenuList, this.rowSize);
    this.filteredItemLength = imageMenuList.length + textMenuList.length;

    this.isCategoryAll = JSON.stringify(this.selectedCategories) === JSON.stringify(this.allCategories);
    this.appendItems(18);
  }

  private sortedList(menuViewModel: MenuViewModel[]) {
    const newMenuViewModel: MenuViewModel[] = cloneDeep(menuViewModel);
    const sortKey = this.sortOption.split('-')[0];
    const orderByKey = this.sortOption.split('-')[1];

    if (sortKey === 'price') {
      if (orderByKey === 'asc') {
        newMenuViewModel.sort((a, b) => (b.price - a.price));
      } else {
        newMenuViewModel.sort((a, b) => (a.price - b.price));
      }
    } else {
      newMenuViewModel.sort((a, b) => (a.sortKey - b.sortKey));
    }

    return newMenuViewModel;
  }

  private sliceList(menuViewModel: MenuViewModel[], rowSize: number) {
    const menuGrid: { row: MenuViewModel[]; }[] = [];
    for (let i = 0; i < menuViewModel.length; i += rowSize) {
      menuGrid.push({
        row: menuViewModel.slice(i, i + rowSize)
      });
    }
    return menuGrid;
  }

  setSearchText(event: CustomEvent) {
    const { value } = event.detail;
    if (value.length > 0) {
      this.searchKey = value;
    } else {
      this.searchKey = undefined;
    }
    this.initInfiniteScrollLength();
    this.updateView();
  }

  optionPanel() {
    if (this.showOptionPanel) {
      this.showOptionPanel = !this.showOptionPanel;
      setTimeout(() => {
        this.displayNone = true;
      }, 400);
    } else {
      this.displayNone = false;
      setTimeout(() => {
        this.showOptionPanel = !this.showOptionPanel;
      });
    }
  }

  goShop(shopNo: string, foodNo: number) {
    this.router.navigate([`/${this.site}/${shopNo}/`], {
      state: { foodNo },
    });
  }

  closeKeyboad(event: KeyboardEvent) {
    const targetElem = event.target as HTMLInputElement;
    targetElem.blur();
  }

  /********************************************************************
   * 로딩 속도 개선을 위한 infiniteScroll
   ********************************************************************/
  initInfiniteScrollLength() {
    this.currentImageMenuLength = 0;
    this.currentTextMenuLength = 0;
    this.infiniteScroll.disabled = false;
    this.scrollToTop.next();
  }

  appendItems(add: number) {
    this.currentImageMenuLength += add;

    if (this.currentImageMenuLength > this.imageMenuModel.length) {
      this.currentImageMenuLength = this.imageMenuModel.length;
    }

    if (this.currentImageMenuLength === this.imageMenuModel.length) {
      this.currentTextMenuLength += add;
    }

    if (this.currentTextMenuLength > this.textMenuModel.length) {
      this.currentTextMenuLength = this.textMenuModel.length;
    }

    this.imageMenuViewModel = this.imageMenuModel.slice(0, this.currentImageMenuLength);
    this.textMenuViewModel = this.textMenuModel.slice(0, this.currentTextMenuLength);
  }

  loadData(event: any) {
    setTimeout(() => {
      this.appendItems(18);
      event.target.complete();

      // // App logic to determine if all data is loaded
      // // and disable the infinite scroll
      if ((this.currentImageMenuLength === this.imageMenuModel.length)
        && (this.currentTextMenuLength === this.textMenuModel.length)) {
        event.target.disabled = true;
      }
    }, 0);
  }
}
