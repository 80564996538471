<ion-header translucent>
	<ion-toolbar>
    <app-back-button [defaultHref]="currentSite"></app-back-button>
	</ion-toolbar>
</ion-header>

<ion-content fullscreen class="menu-container">
	<ion-list *ngIf="food" class="menu-list ion-no-margin">
    <div class="food-header">
      <div *ngIf="food.imageUrl" class="food-image">
        <img [src]="food.imageUrl"
            onerror="this.src='assets/images/logo_placeholder.png';"/>
      </div>
      <h2>{{ food.foodName }}</h2>
      <p class="remark" *ngIf="food.remark">{{food.remark}}</p>
    </div>

    <!-- https://stackoverflow.com/questions/46487001/how-to-show-a-placeholder-image-before-the-actual-image-loads-in-an-ionic-2-or-3 -->
    <!-- <div style="overflow: hidden; width: 320px; height: 200px;">
      <ion-img src="assets/images/placeholder.png" [hidden]="imageLoaded"></ion-img>
      <ion-img [src]="food.imageUrl" (ionImgDidLoad)="imageLoaded = true" [style.opacity]="imageLoaded ? 1 : 0"></ion-img>
    </div> -->
  
    <ion-item-group *ngFor="let foodOptGroup of food.foodOptGroups; let foodOptGroupIndex = index; let firstGroup = first">
      
      <ion-item-group *ngIf="foodOptGroup._controlType === 'checkbox'">
        <ion-item-group *ngFor="let foodOpt of foodOptGroup.foodOpts; let firstOpt = first; let foodOptIndex = index">
          <ion-item *ngIf="firstOpt" lines="none" class="option-name ion-no-padding ion-no-margin">
            <ion-label>
              {{ foodOptGroup.optGrpName }}
            </ion-label>
            <ion-note slot="end">
              {{ foodOptGroup.optGrpMaxSel }}개 선택 가능
            </ion-note>
          </ion-item>
          <ion-item [disabled]="foodOpt.optSoldOut">
            <ion-label>{{ foodOpt.optName }} <ion-badge *ngIf="foodOpt.optSoldOut" color="danger">품절</ion-badge></ion-label>
            <ion-note slot="end">{{ foodOpt.optPrice | number }}원</ion-note>
            <ion-checkbox slot="start"
              mode="md"
              [disabled]="disabled(foodOptGroupIndex, foodOptIndex)"
              [checked]="checked(foodOptGroupIndex, foodOptIndex)"
              (ionChange)="onCheckboxChange(foodOptGroupIndex, foodOptIndex, $event)">
            </ion-checkbox>
          </ion-item>
        </ion-item-group>
      </ion-item-group>

      <ion-radio-group *ngIf="foodOptGroup._controlType === 'radio'"
        [value]="radioValue(foodOptGroupIndex)"
        (ionChange)="onRadioChange(foodOptGroupIndex, $event)">
        <ion-item-group *ngFor="let foodOpt of foodOptGroup.foodOpts; let firstOpt = first; let foodOptIndex = index">
          <ion-item *ngIf="firstOpt" lines="none" class="option-name ion-no-padding ion-no-margin">
            <ion-label>
              {{ foodOptGroup.optGrpName }}
            </ion-label>
          </ion-item>
  
          <ion-item [disabled]="foodOpt.optSoldOut">
            <ion-label>{{ foodOpt.optName ? foodOpt.optName : '기본' }} <ion-badge *ngIf="foodOpt.optSoldOut" color="danger">품절</ion-badge></ion-label>
            <ion-note slot="end">{{ foodOpt.optPrice | number }}원</ion-note>
            <ion-radio slot="start" [value]="foodOptIndex" mode="md"></ion-radio>
          </ion-item>
        </ion-item-group>
      </ion-radio-group>
    </ion-item-group>
  </ion-list>
  
  <div class="food-quantity" lines="none">
    <h3>수량</h3>
    <div class="button-container">
      <ion-icon name="remove-circle-outline" (click)="changeFoodQty('decrease')"></ion-icon>
      <span>{{ foodQty }}</span>
      <ion-icon name="add-circle-outline" (click)="changeFoodQty('increase')"></ion-icon>
    </div>
  </div>

  <!-- <div class="inform">
    <p>최소 주문금액</p>
  </div> -->
</ion-content>

<ion-footer>
  <ion-button class="add-food" expand="block" *ngIf="foodQty > 0" [disabled]="food.foodSoldOut" (click)="addFood(food)">
    <p *ngIf="food.foodSoldOut">품절</p>
    <p *ngIf="!food.foodSoldOut"><b>{{ foodQty }}개</b> ({{ sum() | number }}원) 추가</p>
    <span *ngIf="!food.foodSoldOut">총 주문금액 : {{ this.foodOrdPrice + sum() | number }}원</span>
  </ion-button>

</ion-footer>
