/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { serverTimestamp, Timestamp } from 'firebase/firestore';
import { BehaviorSubject, Subscription } from 'rxjs';

import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';

import { KiccCardOrder } from '../../schema/1/schema-kicc-api';
import { KiccCardOrderDoc, KiccResultDoc, KiccResultDocs } from '../../schema/2/schema-kicc';
import { UnifiedOrder } from '../../schema/3/schema';


const kiccOrderPath = 'kiccOrder';
const kiccResultPath = 'kiccResult';

@Injectable({
  providedIn: 'root'
})
export class KiccOrderService {
  kiccResult: KiccResultDoc[];
  latestKiccResultSubject = new BehaviorSubject<KiccResultDoc[]>([]);
  subscription: Subscription;

  constructor(private db: AngularFirestore) { }

  async createKiccOrder(kiccOrder: KiccCardOrder, unifiedOrder: Partial<UnifiedOrder>) {
    const docRef0 = this.db.firestore.collection(kiccOrderPath).doc();
    const id = `kicc-${docRef0.id}`;

    const docRef = this.db.firestore.collection(kiccOrderPath).doc(id);

    const doc: Partial<KiccCardOrderDoc> = {
      ...kiccOrder,
      _id: id,
      _instanceNo: docRef0.id,
      _organization: unifiedOrder.organization,
      _site: unifiedOrder.site,
      _room: unifiedOrder.room,
      _timeCreate: serverTimestamp() as Timestamp
    };

    await this.db.doc<Partial<KiccCardOrderDoc>>(docRef).set(doc);
  }

  getKiccResultFor(orderNo: string) {
    const queryFn: QueryFn = ref => {
      return ref.where('order_no', '==', orderNo);
    };

    const collection = this.db.collection<KiccResultDocs>(kiccResultPath, queryFn);
    const kiccResult = collection.get();

    return kiccResult.toPromise();
  }
}
