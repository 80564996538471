/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { UnifiedOrderDoc } from '../../schema/3/schema';
import { SiteService } from '../../core/3/site.service';
import { UnifiedOrderService } from '../../core/4/unified-order.service';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit, OnDestroy {
  private destroySignal = new Subject<void>();

  siteName: string;
  order: UnifiedOrderDoc;
  roomNo: string;
  roomKey: string;

  constructor(
    private unifiedOrderService: UnifiedOrderService,
    private siteService: SiteService,
    private navController: NavController,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.observeUnifiedOrder();
  }

  ngOnDestroy() {
    this.destroySignal.next();
    this.destroySignal.unsubscribe();
  }

  observeUnifiedOrder() {
    this.unifiedOrderService.latestUnifiedOrderForUserTelSubject
      .pipe(takeUntil(this.destroySignal))
      .subscribe(orderDoc => {
        const orderNo = this.route.snapshot.paramMap.get('order');
        const order = orderDoc.find(doc => doc.orderNo === orderNo);  // 선택한 주문을 찾는다.
        const siteName = this.siteService.site[order.site]?.siteName;

        this.siteName = siteName;
        this.order = order;
        this.roomKey = order.room;
        this.roomNo = order.room.split('-')[2];
      });
  }

  goSiteMap(roomNo: string) {
    this.navController.navigateForward(`/map/${this.order.site}?room=${roomNo}`, { animated: false });
  }

  goBack() {
    this.navController.back();
  }
}
