/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { BehaviorSubject, SubscriptionLike } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { FingerFaceConf } from '../../schema/1/schema-finger';

import { UtilService } from '../1/util.service';
import { LogService } from '../3/log.service';

const confCollectionPath = 'conf';

@Injectable({
  providedIn: 'root'
})
export class ConfService {
  fingerFaceConf: FingerFaceConf;
  lastestFingerFaceConfSubject = new BehaviorSubject<FingerFaceConf>({ closed: {}, menuFilter: { priceLimit: 0, words: [] } });
  fingerFaceSubscription: SubscriptionLike;

  constructor(
    private db: AngularFirestore,
    private utilService: UtilService,
    private logService: LogService
  ) {
  }

  observeFingerFaceConf() {
    const docRef = this.db.doc<FingerFaceConf>(`${confCollectionPath}/finger-face`);

    this.fingerFaceSubscription = docRef.valueChanges().subscribe(doc => {
      this.fingerFaceConf = doc;
      this.lastestFingerFaceConfSubject.next(doc);
    }, error => {
      this.utilService.toastError(`FingerFace 정보를 불러오는데 실패했습니다. 새 창에서 다시 시도해주세요.`);
      this.logService.error(`observeFingerFace()에서 에러 발생 : ${error}`);
    });
  }

  getFingerFaceConf() {
    const docRef = this.db.doc<FingerFaceConf>(`${confCollectionPath}/finger-face`);

    return docRef.get().toPromise();
  }
}
