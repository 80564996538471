/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import { UtilService } from '../../core/1/util.service';
import { RoomPosForMap } from '../../core/1/room-coordinates-mapping';
import { SiteService } from '../../core/3/site.service';
import { LogService } from '../../core/3/log.service';

interface RoomPin {
  roomNumber: number;
  pinPosition: {
    top: number;
    left: number;
  };
}
@Component({
  selector: 'app-zoning-map',
  templateUrl: './zoning-map.component.html',
  styleUrls: ['./zoning-map.component.scss']
})
export class ZoningMapComponent implements OnInit, AfterViewInit {
  @ViewChild('pinBoard', { static: true }) pinBoard: ElementRef;
  site: string;
  room: RoomPin;
  siteName: string;
  mapImageUrl: string;
  widthAuto = false;
  showBackButton = false;
  transitionOn = false;

  constructor(
    private route: ActivatedRoute,
    private siteService: SiteService,
    private navController: NavController,
    private logService: LogService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    const hasPrevPage = window.history.length > 2;

    const urlPrefix = 'https://ssproxy.ucloudbiz.olleh.com/v1/AUTH_d722d13e-44ea-44ad-8c9b-2f5763ce3d40/ghostkitchen/site/';
    const site = this.route.snapshot.paramMap.get('site');
    const roomKey = this.route.snapshot.queryParamMap.get('room');

    const siteName = this.siteService.site[site]?.siteName;
    const pinPosition = RoomPosForMap[`${site}-${roomKey}`];

    if (siteName === undefined || pinPosition === undefined) {
      this.utilService.toastError('잘못된 주소입니다.', undefined, 5000);
      this.logService.error(`존재하지 않는 지점 또는 호실로 접속했습니다. site: ${site}, room: ${roomKey}`);
      setTimeout(() => {
        if (hasPrevPage) {
          this.navController.back({ animated: false });
          this.navController.pop();
        } else {
          this.navController.navigateRoot('', { animated: false });
        }
      }, 2000);
    }

    const room: RoomPin = {
      roomNumber: Number(roomKey),
      pinPosition: {
        left: pinPosition[0],
        top: pinPosition[1]
      }
    };

    this.site = site;
    this.room = room;
    if (site === 'gk-songpa' || site === 'gk-nowon') {
      this.widthAuto = true;
    }

    this.siteName = siteName;
    this.mapImageUrl = urlPrefix + site + '-map.png';
    this.showBackButton = hasPrevPage;
  }

  ngAfterViewInit() {
    this.trasitionTriger();
  }

  public goBack() {
    this.navController.back({ animated: false });
  }

  private trasitionTriger() {
    setTimeout(() => {
      this.transitionOn = true;
    }, 200);
  }
}
