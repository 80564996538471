/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loadingElement: HTMLIonLoadingElement;

  constructor(
    private loadingController: LoadingController
  ) { }

  async presentLoading() {
    this.loadingElement = await this.loadingController.create({
      message: `잠시만 기다려주세요.`,
      duration: 60000
    });
    return this.loadingElement.present();
  }

  async dismissLoading() {
    if (this.loadingElement) {
      await this.loadingElement.dismiss();
    }
  }
}
