/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';

import { ShoppingCartService } from './pages/shopping-cart/shopping-cart.service';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartGuardService implements CanActivate {
  constructor(
    private router: Router,
    private shoppingCartService: ShoppingCartService
  ) { }

  promiseForInit(url: string) {
    return new Promise<boolean>((resolve) => {
      if (this.shoppingCartService.shopDetail) {
        resolve(true);
      } else {
        resolve(false);
        this.router.navigate([ url ]);
      }
    });
  }

  canActivate(route: ActivatedRouteSnapshot) {
    const url = `${route.paramMap.get('site')}`;
    return this.promiseForInit(url);
  }
}
