/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ModalController, IonContent, IonInput } from '@ionic/angular';

import { AugmentedAddress } from '../../../schema/1/schema-common';
import { KakaoKeywordAddressDocument, KakaoKeywordSearchAddress } from '../../../schema/1/schema-kakao-api';
import { CallInputAugmentAddress, CallOutputAugmentAddress } from '../../../schema/4/schema-functions-call';

import { UtilService } from '../../../core/1/util.service';
import { kakao_keyword_address_search } from '../../../core/1/kakao-address-search-api';
import { LogService } from '../../../core/3/log.service';
import { LoadingService } from '../../loading/loading.service';

@Component({
  selector: 'app-address-search-modal',
  templateUrl: './address-search-modal.component.html',
  styleUrls: ['./address-search-modal.component.scss']
})
export class AddressSearchModalComponent implements OnInit {
  @Input() public getAddress: (resopnse: AugmentedAddress) => void;

  @ViewChild(IonContent) content: IonContent;
  @ViewChild('Search', { static: false }) searchInput: IonInput;

  public addressForm: FormControl<string>;

  public documents: KakaoKeywordAddressDocument[];
  public pagination = {
    pageNumber: 1,
    pageLength: 1,
    totalCount: 0,
    size: 15,
  };

  constructor(
    private modalController: ModalController,
    private utilService: UtilService,
    private loadingService: LoadingService,
    private logService: LogService,
    private fns: AngularFireFunctions
  ) { }

  ngOnInit() {
    this.addressForm = new FormControl({
      value: '',
      disabled: false
    }, this.searchAddressValidator());

    this.autoFocus();
  }

  public async dismissModal() {
    await this.modalController.dismiss();
  }

  public async paginate(direction: 'prev' | 'next') {
    let pageNumber = this.pagination.pageNumber;
    if (direction === 'prev' && pageNumber > 1) {
      --pageNumber;
    } else if (pageNumber < this.pagination.pageLength) {
      ++pageNumber;
    }

    if (pageNumber === this.pagination.pageNumber) {
      return;
    } else {
      this.pagination.pageNumber = pageNumber;
      await this.search(pageNumber);
      this.content.scrollToTop();
    }
  }

  public async pickAddress(rawAddressRoad: string, rawAddressJibun: string) {
    try {
      await this.loadingService.presentLoading();

      const resopnse = await this.augmentAddress(rawAddressRoad, rawAddressJibun);
      this.getAddress(resopnse);
      this.dismissModal();
    } catch (error) {
      this.utilService.toastError(error.message);
    } finally {
      this.loadingService.dismissLoading();
    }
  }

  public initForm() {
    this.addressForm.setValue('');
  }

  public autoFocus() {
    // Modal open 애니메이션이 완료된 후 Focus한다.
    setTimeout(() => {
      this.searchInput.setFocus();
    }, 600);
  }

  private searchAddressValidator(): ValidatorFn {
    return (control: FormControl<string>): ValidationErrors | null => {
      const value = control.value;

      if (value && value.length > 0) {

        // 특수문자 제거
        const expText = /[%=><]/;
        if (expText.test(value) === true) {
          this.addressForm.setValue(value.split(expText).join(''));
          return { reason: '특수문자를 입력 할수 없습니다.' };
        }
      } else {
        return { reason: '예) 서초동1321-5 또는 ㅇㅇ빌딩' };
      }
    };
  }

  public async search(page?: number) {
    const keyword = this.addressForm.value;
    if (keyword.length < 1) {
      return;
    }

    if (page === undefined) {
      this.pagination.pageNumber = 1;
    }

    try {
      const response = await kakao_keyword_address_search(keyword, page);
      const result = await response.json() as KakaoKeywordSearchAddress;
      this.documents = result.documents;
      this.pagination.totalCount = result.meta.total_count;
      this.pagination.pageLength = Math.floor(this.pagination.totalCount > this.pagination.size
        ? this.pagination.totalCount / this.pagination.size
        : 1
      );

    } catch (error) {
      this.logService.error(`주소 찾기 실패(address-search-modal) : ${error}`);
    }
  }

  private async augmentAddress(rawAddressRoad: string, rawAddressJibun: string) {
    try {
      let resMessage: CallOutputAugmentAddress;
      const callInput = { organization: '', site: '', room: '', rawAddress: rawAddressRoad, from: 'fingerFace' };
      const requestAugmentAddress = this.fns.httpsCallable<CallInputAugmentAddress, CallOutputAugmentAddress>('callAugmentAddress');
      resMessage = await requestAugmentAddress(callInput).toPromise();

      if (resMessage.result === 'success') {
        return resMessage.augmentedAddress;
      } else {
        callInput.rawAddress = rawAddressJibun;
        // 도로명 검색으로 모호한 응답(복수의 응답)이 오는 경우 지번 주소로 한 번 더 시도한다.
        resMessage = await requestAugmentAddress(callInput).toPromise();

        if (resMessage.result === 'success') {
          return resMessage.augmentedAddress;
        }

        throw new Error(`주소 확인 실패 : ${resMessage.reason}`);
      }
    } catch (error) {
      if (error.name === 'TimeoutError') {
        throw new Error('시간 초과 : 명령에 응답을 하지 않습니다.');
      } else {
        throw new Error(`에러 : ${error.message}`);
      }
    }
  }
}
