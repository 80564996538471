<ion-header translucent>
  <ion-toolbar>
    <app-back-button [defaultHref]="currentSite"></app-back-button>
    <ion-title>장바구니</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen class="shopping-cart-container">
  <ion-list lines="none" class="order-food-list">
    <h4 class="shop-name" *ngIf="shopName">{{ shopName }}</h4>
    <ion-item-group *ngFor="let unifiedOrderFood of unifiedOrderFoods; let idx = index">
      <ion-item class="food-name">
        <ion-label>{{ unifiedOrderFood.foodName }}</ion-label>
        <ion-note color="danger" slot="end"
          (click)="removeFood(idx)">삭제</ion-note>
      </ion-item>

      <ion-item-group>
        <ion-item class="option-name"
          *ngFor="let foodOpt of unifiedOrderFood.foodOpts">
          <ion-label>+ {{ foodOpt.optName ?  foodOpt.optName : '기본' }}{{ foodOpt.optPrice > 0 ? '(' + (foodOpt.optPrice | number) + '원)': ''}}</ion-label>
        </ion-item>
      </ion-item-group>

      <ion-item class="food-quantity">
        <span>{{ unifiedOrderFood.foodOrdPrice | number }}원</span>
        <div class="button-container">
          <ion-icon name="remove-circle-outline" (click)="decreaseFoodQty(idx)"></ion-icon>
          <span>{{ unifiedOrderFood.foodQty }}</span>
          <ion-icon name="add-circle-outline" (click)="increaseFoodQty(idx)"></ion-icon>
        </div>
      </ion-item>

      <div class="divider"></div>
    </ion-item-group>

    <div class="back-to-shop">
      <button (click)="goBackToShop()">
        <ion-icon name="add-outline"></ion-icon>
        <span>메뉴추가</span>
      </button>
    </div>
  </ion-list>
  
  <div class="price-list">
    <!-- <div class="price-item">
      <p>할인</p>
      <p>{{ 0 | number}}원</p>
    </div> -->
    <div class="price-item total">
      <p>총 주문금액</p>
      <p>{{ totalAmount | number }}원</p>
    </div>
  </div>
</ion-content>


<ion-footer>
  <ion-button expand="block" *ngIf="totalQty > 0" (click)="this.goOrder('TAKEOUT')">
    <b class="price">{{ totalAmount | number }}원</b> 주문하기
  </ion-button>
</ion-footer>