<ion-header>
  <ion-toolbar>
    <ion-title>카테고리</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list>
    <ion-item (click)="isAllChecked ? toggleSelectAll(false) : toggleSelectAll(true)">
      <ion-label>전체 <span class="amount">({{ totalAmount }})</span></ion-label>
      <!-- <ion-note slot="end" [color]="isAllChecked ? 'primary' : undefined ">{{ totalAmount }}</ion-note> -->
      <ion-checkbox color="primary" [checked]="isAllChecked" slot="end"></ion-checkbox>
    </ion-item>
    <ion-item *ngFor="let item of checkBoxList">
      <ion-label>{{ item.value }} <span class="amount">({{ item.amount }})</span></ion-label>
      <!-- <ion-note slot="end" [color]="item.isChecked ? 'primary' : undefined ">{{ item.amount }}</ion-note> -->
      <ion-checkbox color="primary" [(ngModel)]="item.isChecked" slot="end" (ionChange)="handleCheckBox()"></ion-checkbox>
    </ion-item>
  </ion-list>
</ion-content>
<ion-footer>
  <ion-button [disabled]="isEmpty" expand="block" (click)="selectCategories()">확인</ion-button>
</ion-footer>