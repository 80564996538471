<div [class.hide]="!showUp">
  <ion-item-group [class.loaded]="isLoading">
    <div *ngIf="dummyList">
      <a *ngFor="let i of dummyList"
        class="shop-item"
        (click)="undefined">
        <div class="image-mask loading">
          <img src="assets/images/placeholder.png" />
        </div>
        <div class="room-title">
          <p class="loading">loading</p>
        </div>
      </a>
    </div>
  </ion-item-group>
  
  <ion-item-group [class.loaded]="!isLoading">
    <a *ngFor="let item of shopViewModel"
      class="shop-item"
      (click)="item.isAvailable ? goShop(item.shopNo) : undefined"
      routerLinkActive="active">
      <div *ngIf="!item.isAvailable" class="closed-shop">
        <span>준비중</span>
      </div>
      <div class="image-mask">
        <img *ngIf="item.thumbnailUrl" 
          [src]="item.thumbnailUrl"/>
      </div>
      <div class="room-title">
        <p>{{ item.shopName }}</p>
        <div class="position-label">
          <span class="section">{{ item.section }}</span>
          <span class="room-number">{{ item.roomNo }}</span>
        </div>
      </div>
    </a>
    <!-- 
      마지막 줄 아이템이 좌측으로 붙도록 하기 위해서 수평자리 차지용 아이템을 추가한다.
      refer: https://stackoverflow.com/questions/18744164/flex-box-align-last-row-to-grid
    -->
    <a class="shop-item"></a>
    <a class="shop-item"></a>
    <a class="shop-item"></a>
    <a class="shop-item"></a>
    <a class="shop-item"></a>
    <a class="shop-item"></a>
    <a class="shop-item"></a>
    <a class="shop-item"></a>
    <a class="shop-item"></a>
    <a class="shop-item"></a>
  </ion-item-group>
</div>