/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { IonContent } from '@ionic/angular';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, AfterViewInit, ViewChildren, QueryList, ElementRef, Renderer2, ViewChild } from '@angular/core';

import { UnifiedMenuDoc, UnifiedMenuFood } from '../../schema/3/schema';

import { UtilService } from '../../core/1/util.service';
import { UnifiedMenuService } from '../../core/1/unified-menu.service';
import { sleep } from '../../core/2/util';

import { environment } from '../../../environments/environment';
import { LoadingService } from '../../shared/loading/loading.service';
import { ShoppingCartService } from '../shopping-cart/shopping-cart.service';

interface ShopViewModel {
  roomNo: number;
  shopName: string;
  shopNo: string;
  menus: FoodGrp[];
}
interface FoodGrp {
  foodGrpName?: string;
  foods: MenuFood[];
}
interface MenuFood extends UnifiedMenuFood {
  foodNo: number;
}
@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit, OnDestroy, AfterViewInit {
  // 메뉴보기에서 넘어온 경우 해당 메뉴를 표시하기 위해 사용한다.
  @ViewChildren('foodRef') foodRefs: QueryList<ElementRef>;
  @ViewChild('IonContent') ionContent: IonContent;
  public currentSite: string;

  // UI 표시용
  public shopViewModel: ShopViewModel;
  public totalQty = 0;
  public totalAmount = 0;
  public foodNo: number | undefined = undefined;

  public payable = environment.payable;

  private readonly KT_STATIC_PREFIX = 'https://ssproxy.ucloudbiz.olleh.com/v1/AUTH_d722d13e-44ea-44ad-8c9b-2f5763ce3d40/ghostkitchen/toe-menu-images';

  private currentShop: string;
  private unifiedMenusubscription: Subscription;
  private shoppingCartSubscription: Subscription;

  constructor(
    private unifiedMenuService: UnifiedMenuService,
    private shoppingCartService: ShoppingCartService,
    private utilService: UtilService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.initialize();
    this.foodNo = (this.location.getState() as { foodNo: number }).foodNo;
  }

  ngOnDestroy() {
    this.unifiedMenusubscription?.unsubscribe();
    this.shoppingCartSubscription?.unsubscribe();
  }

  initialize() {
    this.currentSite = this.route.snapshot.paramMap.get('site');
    this.currentShop = this.route.snapshot.paramMap.get('shop');

    this.observeUnifiedMenu();
    this.observeShoppingCart();
  }

  async ngAfterViewInit() {
    if (this.foodNo !== undefined) {
      const destElement = this.foodRefs.find(ref => ref.nativeElement.className === 'auto-scroll-target');

      // 최초 offsetTop값이 설정될 수 있도록 실행 순서를 의도적으로 미룬다.
      await sleep(100);
      const offsetTop = destElement.nativeElement.offsetTop;
      // offset값을 기준으로 애니메이션 타이밍을 정한다. 단 최대 딜레이 700ms
      await sleep(Math.min(offsetTop, 700));
      this.ionContent.scrollToPoint(0, (offsetTop - 100), 700);

      // blink-animation
      await sleep(500);
      this.renderer.addClass(destElement.nativeElement, 'blink-animation');
      await sleep(1500);
      this.renderer.removeClass(destElement.nativeElement, 'blink-animation');
    }
  }

  /******************************************************************
   * [Observing]
   ******************************************************************/
  observeUnifiedMenu() {
    this.unifiedMenusubscription = this.unifiedMenuService.latestUnifiedMenuForSiteSubject.subscribe(async (unifiedMenuDocs) => {
      if (unifiedMenuDocs.length > 0) {
        const shop: UnifiedMenuDoc = unifiedMenuDocs.find(menu => menu.shopNo === this.currentShop);
        if (shop) {
          const unifiedMenu: UnifiedMenuDoc = cloneDeep(shop);

          this.shopViewModel = {
            roomNo: Number(unifiedMenu.room.split('-')[2]),
            shopName: unifiedMenu.shopName,
            shopNo: unifiedMenu.shopNo,
            menus: unifiedMenu.menus.map((menu, menuIndex) => {
              const menuFood: MenuFood[] = menu.foods.map((food, foodIndex) => {
                const imageUrl = food?.imageUrl ? food.imageUrl.replace(/(.*).com/, this.KT_STATIC_PREFIX) : '';

                return {
                  ...food,
                  imageUrl,
                  foodNo: this.createFoodNo(menuIndex, foodIndex)
                };
              });
              return {
                foodGrpName: menu.foodGrpName,
                foods: menuFood
              };
            }),
          };
        } else {
          this.utilService.toastWarning('존재하지 않는 URL입니다.');
          this.router.navigateByUrl(this.currentSite);
        }
        this.loadingService.dismissLoading();
      } else {
        await this.loadingService.presentLoading();
      }
    });
  }

  observeShoppingCart() {
    // Menu 페이지로 이동 후에도 이 컴포넌트는 존재하기 때문에 Back으로 돌아온 경우를 대비해 구독한다.
    this.shoppingCartSubscription = this.shoppingCartService.latestUnifiedOrderFoodsSubject.subscribe(unifiedOrderFoods0 => {
      this.totalAmount = unifiedOrderFoods0.reduce((sum, food) => sum + food.foodOrdPrice, 0);
      this.totalQty = unifiedOrderFoods0.reduce((sum, food) => sum + food.foodQty, 0);
    });
  }

  /******************************************************************
   * [Routing]
   ******************************************************************/
  goToMenuForm(url: string) {
    this.router.navigate([url], { relativeTo: this.route });
  }

  goOrder() {
    this.router.navigate(['../cart'], { relativeTo: this.route });
  }

  goBaemin() {
    location.href = `https://www.baemin.com/shopDetail?shopDetail_shopNo=${this.shopViewModel.shopNo}&bm_rfr=SHARE&shopDetail_campaignId=-1&shopDetail_categoryTypeCode=1`;
  }

  private createFoodNo(menuIndex: number, foodIndex: number) {
    const prefix = String(menuIndex).padStart(2, '0');
    const suffix = String(foodIndex).padStart(2, '0');
    return Number(prefix + suffix);
  }
}
