/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';

import { UtilService } from './core/1/util.service';
import { ShoppingCartService } from './pages/shopping-cart/shopping-cart.service';

@Injectable({
  providedIn: 'root'
})
export class OrderGuardService implements CanActivate {
  constructor(
    private shoppingCartService: ShoppingCartService,
    private utilService: UtilService,
    private router: Router
  ) { }

  promiseForInit(route: ActivatedRouteSnapshot) {
    return new Promise<boolean>((resolve) => {
      const shopNo = this.shoppingCartService.shopNo;

      if (shopNo === undefined) {
        const currentSite = route.paramMap.get('site');
        resolve(false);
        this.utilService.toastInfo(`장바구니가 비어있습니다.`, undefined, 3000);
        this.router.navigate([currentSite], { replaceUrl: true });
      } else {
        resolve(true);
      }
    });
  }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.promiseForInit(route);
  }
}
