/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import fecha from 'fecha';
import { Subscription, combineLatest } from 'rxjs';

import { Router, ActivatedRoute } from '@angular/router';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';

import { FingerFaceConf, OrderStep, UserAddress } from '../../schema/1/schema-finger';
import { UnifiedOrderFood, UnifiedOrderDeliveryType, AugmentedAddress } from '../../schema/1/schema-common';
import { KiccCardOrder, KiccResult, KiccPayType } from '../../schema/1/schema-kicc-api';
import { UnifiedOrder, UnifiedOrderStatusCode, UnifiedOrderContextStatusCode, RoomDoc } from '../../schema/3/schema';

import { GtagService } from '../../core/1/gtag.service';
import { UtilService } from '../../core/1/util.service';
import { kiccResponseMap } from '../../core/1/string-map';
import { getRequestAuth, getConfirmAuth } from '../../core/1/sms-api';
import { KiccOrderService } from '../../core/1/kicc-order.service';
import { UnifiedMenuService } from '../../core/1/unified-menu.service';
import { normalizingTel } from '../../core/2/util';
import { RoomService } from '../../core/3/room.service';
import { LogService } from '../../core/3/log.service';
import { UnifiedOrderService } from '../../core/4/unified-order.service';
import { ConfService } from '../../core/4/conf.service';

import { ShoppingCartService } from '../../pages/shopping-cart/shopping-cart.service';
import { LocalStorageService } from '../../shared/local-storage/local-storage.service';
import { AlertNoticeService } from '../../shared/alert-notice/alert-notice.service';
import { LoadingService } from '../../shared/loading/loading.service';
import { ModalService } from '../../shared/modal/modal.service';
import { SafePipe } from '../../shared/safe.pipe';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.scss'],
  providers: [SafePipe]
})
export class OrderFormComponent implements OnInit, OnDestroy, AfterViewInit {
  // KICC결제 모듈을 사용하기 위해 구성한 php서버의 페이지 Iframe
  @ViewChild('Iframe', { static: false }) IframeRef: ElementRef;
  iframeDomain: string;
  iframeUrl: SafeResourceUrl;
  kiccOrder: KiccCardOrder;
  kiccResult: KiccResult;
  isResultResponsed = false;
  spMallId = 'T0010131';
  spPayType: KiccPayType = '11';

  // 문자 인증
  sessionId: string;
  confirmAuth = false;
  authCode: string;
  expires: string;

  orderForm: FormGroup<{
    userTel: FormControl<string>;
    address_detail: FormControl<string>,
    orderMsg: FormControl<string>;
    authCode: FormControl<string>,
  }>;
  isOrderAvail: 'Y' | 'N' = 'Y';

  currentSite: string;
  shopNo: string;
  room: RoomDoc;
  // siteName: string;
  unifiedOrderFoods: UnifiedOrderFood[] = [];

  // UI 표시용
  deliveryType: UnifiedOrderDeliveryType;
  step: OrderStep = 'order';
  totalQty = 0;
  totalAmount = 0;
  noDisposableItem = false;
  availableLocalStorage = true;
  useLocalStorage = true;
  userStateLoaded = false;

  // Data model
  docRefId = '';
  unifiedOrder: Partial<UnifiedOrder> = {};

  private combineLatestSubscription: Subscription = null;
  private shoppingCartsubscription: Subscription = null;

  constructor(
    private shoppingCartService: ShoppingCartService,
    private confService: ConfService,
    private roomService: RoomService,
    private logService: LogService,
    private unifiedMenuService: UnifiedMenuService,
    private unifiedOrderService: UnifiedOrderService,
    private kiccOrderService: KiccOrderService,
    private loadingService: LoadingService,
    private localStorageService: LocalStorageService,
    private alertNoticeService: AlertNoticeService,
    private modalService: ModalService,
    private utilService: UtilService,
    private gtagService: GtagService,
    private route: ActivatedRoute,
    private router: Router,
    private safePipe: SafePipe,
    private fb: FormBuilder,
  ) {
    this.spMallId = environment.kiccMallId;
    this.iframeDomain = environment.paymentServer;
  }

  ngOnInit() {
    this.initForIframe();
    this.getDataFromRoute();

    this.observe();
    this.observeShoppingCart();

    this.initModel();
    this.initKiccOrderForm();
    this.buildForm();
    this.observeUserTel();

    this.availableLocalStorage = this.localStorageService.checkAvailable();
    this.useLocalStorage = this.availableLocalStorage;
    this.loadLocalUserState();
  }

  getDataFromRoute() {
    this.currentSite = this.route.snapshot.paramMap.get('site');
    this.deliveryType = this.route.snapshot.queryParamMap.get('deliveryType') as UnifiedOrderDeliveryType;
  }

  ngOnDestroy() {
    if (this.combineLatestSubscription) {
      this.combineLatestSubscription.unsubscribe();
    }
    if (this.shoppingCartsubscription) {
      this.shoppingCartsubscription.unsubscribe();
    }

    const key = 'ref';
    window[key] = undefined;
  }

  /******************************************************************
   * [Iframe handling]
   ******************************************************************/
  initForIframe() {
    // 1. dev.kicc.ghostaurant.co의 SOP문제를 피하기 위해 도메인을 상위로 맞춤.
    document.domain = 'ghostaurant.co';
    this.iframeUrl = this.safePipe.transform(this.iframeDomain + '/mobile/mobile/order.php');

    // 2. Child Dom에서 부모의 Function에 접근을 위함.
    const key = 'ref';
    window[key] = { component: this };
  }

  // 결제 진행 상태를 확인한다. (IFrame의 page 갱신이 일어날 때 마다 step을 체크한다.)
  ngAfterViewInit() {
    this.IframeRef.nativeElement.onload = () => {
      if (this.step === 'finish' && !this.isResultResponsed) {
        this.kiccResult = this.IframeRef.nativeElement.contentWindow.getResult();
        this.isResultResponsed = true;
        if (this.kiccResult.res_cd === '0000') {
          this.finishOrder();
        }
      }
    };
  }

  // IFrame에서 호출 - 현재의 컴포넌트(parent)에 step 정보를 전달하기 위함
  updateStep(step: OrderStep) {
    this.step = step;
  }

  // IFrame에서 호출 - 결제창에서 정상결제가 아닌 응답(실패 또는 취소)의 처리
  // 이 경우 직접취소가 아니며 과정상 KiccNotification이 기록되지 않기 때문에 CANCELED가 아닌 BACK으로 상태를 변경한다.
  async kiccAlert(code: string, msg: string) {
    const msgForCode = kiccResponseMap[code] ?? msg;
    this.alertNoticeService.noticeAlert(msgForCode);
    const onCANCELED = fecha.format(new Date(), 'YYYY-MM-DDTHH:mm:ss+0900');

    // [주문상태 변경] STAGING -> BACK
    try {

      await this.unifiedOrderService.mergeOrder({
        _id: this.unifiedOrder._id,
        orderStatusCode: UnifiedOrderStatusCode.BACK,
        contextStatusCode: UnifiedOrderContextStatusCode.BACK,
        cancelReason: `[결제중단된 주문입니다.] 중단 사유: ${msgForCode}`,
        time: {
          onCANCELED
        }
      });

      this.logService.logOrder(this.unifiedOrder as UnifiedOrder, `Easypay 결제 진행 중 중단되어 결제중단(BACK) 주문으로 상태를 변경했습니다. 중단 사유: ${msgForCode}`);
    } catch (err) {
      this.utilService.toastError(`${this.docRefId}/${err.message}`);
      this.logService.logOrder(
        this.unifiedOrder as UnifiedOrder,
        // tslint:disable-next-line:max-line-length
        `결제 중단에 따른 주문상태 변경(STAGING -> BACK)에 실패했습니다. 중단 사유: ${msgForCode}, 주문상태 변경 실패 Error: ${err.message}, kiccCode: ${code}, kiccMsg: ${msg}`,
        'error'
      );
    }
  }

  // IFrame에서 호출
  async presentLoading() {
    await this.loadingService.presentLoading();
  }

  // IFrame에서 호출
  async dismissLoading() {
    await this.loadingService.dismissLoading();
  }

  /******************************************************************
   * [Modeling]
   ******************************************************************/
  initModel() {
    const shopNo = this.shoppingCartService.shopNo;
    const { organization, site, room, shopName } = this.getShopDetailFor(shopNo);
    // Data model
    this.unifiedOrder = {
      ...this.unifiedOrder, ...{
        orderChannel: 'app',
        orderVendor: 'ghostkitchen',
        organization,
        site,
        room,
        shopName,
        shopNo,
        orderNo: '',
        instanceNo: '',

        deliveryType: this.deliveryType,
        orderDate: fecha.format(new Date(), 'YYYY-MM-DDTHH:mm:ss+0900'), // 나중에 최종 적으로 업데이트한다.
        orderStatusCode: UnifiedOrderStatusCode.STAGING,
        orderAmount: 0,
        deliveryTip: 0,
        deliveryMinutes: 0,
        paymentMethod: '선불',
        userTel: '',
        orderMsg: '',

        address_key: '',
        address_detail: '',
        address_sido: '',
        address_sigungu: '',
        address_dong: '',
        address_jibun: '',
        address_dongH: '',
        address_road: '',
        address_building_name: '',
        address_location: {
          lon: 0,
          lat: 0
        },
        vroong: {
          dest_sigungu: '',
          dest_legal_eupmyeondong: '',
          dest_admin_eupmyeondong: '',
          dest_ri: '',
          dest_beonji: '',
          dest_road: '',
          dest_building_number: '',
        },
      }
    };
  }

  initKiccOrderForm() {
    const init: KiccCardOrder = {
      /*--공통--*/
      sp_mall_id: this.spMallId,
      sp_order_no: '',
      sp_pay_type: this.spPayType,
      sp_currency: '00',
      sp_product_nm: '',
      sp_product_amt: '',
      sp_return_url: `${this.iframeDomain}/mobile/mobile/order_res_submit.php`,
      sp_user_phone1: '',
      sp_mall_nm: '고스트키친',
      sp_lang_flag: 'KOR',
      sp_charset: 'EUC-KR',
      sp_user_nm: '일반 고객',
      sp_product_type: '0',
      sp_window_type: 'iframe',
      sp_product_expr: '',
      sp_user_id: '',
      sp_memb_user_no: '',
      sp_user_mail: '',
      sp_user_phone2: '',
      sp_user_addr: '',
      sp_app_scheme: '',
      sp_top_window_url: window.location.href,

      /*--신용카드--*/
      sp_usedcard_code: '',
      sp_quota: '',
    };

    this.kiccOrder = init;
  }

  buildForm() {
    this.orderForm = this.fb.group({
      userTel: [this.unifiedOrder.userTel, this.userTelValidator()],
      address_detail: [this.unifiedOrder.address_detail, this.addressValidator()],
      orderMsg: this.unifiedOrder.orderMsg,
      authCode: '',
    }, { validators: this.formValidator() }); // cross field validation : https://angular.io/guide/form-validation#cross-field-validation

    this.confirmAuth ? this.orderForm.get('userTel').disable() : this.orderForm.get('userTel').enable();
  }

  observeUserTel() {
    const formControl = this.orderForm.get('userTel');
    formControl.valueChanges.forEach(value => {
      const normalizedTel = normalizingTel(value);
      if (value !== normalizedTel) {
        this.orderForm.get('userTel').setValue(normalizedTel);
      }
    });
  }

  userTelValidator(): ValidatorFn {
    return (control: FormControl<string>): ValidationErrors | null => {
      // this.orderForm은 this가 변할 경우에 undefined가 되는 경우가 있다.
      const userTel = control.value;

      if (userTel && userTel.length > 0) {
        const match = userTel.match(/^(0[157]0[1-9]?-[1-9][0-9]{3,4}-[0-9]{4}|02-[2-9][0-9]{2,3}-[0-9]{4})$/);
        if (match === null) {
          return { reason: '전화번호가 형식에 맞지 않습니다.' };
        }
      } else {
        return { reason: '전화번호가 필요합니다.' };
      }
    };
  }

  addressValidator(): ValidatorFn {
    return (control: FormControl<string>): ValidationErrors | null => {
      const addressDetail = control.value;

      if (this.deliveryType === 'DELIVERY') {
        if (!addressDetail || addressDetail.length < 1) {
          return { reason: '상세주소가 필요합니다.' };
        }
      }
    };
  }

  formValidator(): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {

      const orderForm = control;
      if (orderForm) {
        orderForm.get('userTel').updateValueAndValidity({ onlySelf: true });
      }

      return null;
    };
  }

  handleSelect(event: any) {
    this.spPayType = event.target.value;
  }

  updateModel() {
    const optionalMsg = this.noDisposableItem ? '(수저포크X) ' : '';
    this.unifiedOrder = {
      ...this.unifiedOrder, ...{
        userTel: (this.orderForm.get('userTel').value).replace(/-/g, ''),
        address_detail: this.orderForm.get('address_detail').value,
        orderMsg: optionalMsg + this.orderForm.get('orderMsg').value,
      }
    };
  }

  /******************************************************************
   * [KICC 결제]
   * 1. order()                       결제 인증 요청 - kiccCardOrderDoc 생성
   *    - deferredPayOrder()          후불결제
   *    - advancePayOrder()           선불결제
   *      - createOrder()             주문서 작성 - UnifiedOrderDoc 생성
   * 2. finishOrder()                 결제 완료 - UnifiedOrderDoc 상태변경
   ******************************************************************/
  async order() {
    // 주문전에 마지막으로 업소상태를 확인한다.
    const fingerFaceConf = await this.confService.getFingerFaceConf();
    const { closed } = (fingerFaceConf.data() as FingerFaceConf);
    if ((this.isOrderAvail === 'N') || closed[this.currentSite].isClosed) {
      this.alertNoticeService.noticeAlert('죄송합니다. 준비중인 업소입니다.');
      this.shoppingCartService.emptyCart();
      return this.goHome();
    }

    // UI 내용을 unifiedOrder에 반영한다.
    this.updateModel();

    // 주소와 전화번호 LocalStorage에 저장
    if (this.useLocalStorage) {
      this.saveLocalUserState();
    }

    // 후불과 선불결제를 구분한다.
    if (Number(this.spPayType) < 11) {
      this.deferredPayOrder();
    } else {
      this.advancePayOrder();
    }
  }

  // 후불결제
  async deferredPayOrder() {
    const result = await this.createOrder('deferred');
    if (!result) { return this.goHome(); }

    await this.loadingService.presentLoading();
    this.gtagService.page('/buy');

    await this.loadingService.dismissLoading();
    this.alertNoticeService.noticeAlertConfirm('잠시 후 접수가 완료되면<br>예상 배달 시간을<br>문자로 알려드립니다.', () => this.goHome(), false);
  }

  // 선불결제
  async advancePayOrder() {
    const result = await this.createOrder('advance');
    if (!result) { return this.goHome(); }

    await this.loadingService.presentLoading();
    try {
      // 필수 항목 누락 여부 확인
      if (!this.kiccOrder.sp_order_no) {
        this.loadingService.dismissLoading();
        this.alertNoticeService.noticeAlert(`주문 실패 : 가맹점 주문번호가 비어있습니다.`);
        return;
      } else if (Number(this.kiccOrder.sp_product_amt) <= 0) {
        this.loadingService.dismissLoading();
        this.alertNoticeService.noticeAlert(`주문 실패 : 결제할 금액이 없습니다.`);
        return;
      }
      // 1. 결제 인증내용(kiccOrder) 기록
      await this.kiccOrderService.createKiccOrder(this.kiccOrder, this.unifiedOrder);

      // 2. Iframe으로 결제내용 전송
      this.IframeRef.nativeElement.contentWindow.submitOrder(this.kiccOrder);
      this.loadingService.dismissLoading();
    } catch (error) {
      await this.loadingService.dismissLoading();
      const { src } = this.IframeRef.nativeElement;

      if (this.isIOSInAppBrowser()) {
        this.alertNoticeService.noticeAlert('현재 환경(in-app)에서는 결제를 진행할 수 없습니다. Safari로 접속해주세요.');
        // tslint:disable-next-line:max-line-length
        this.logService.logOrder(this.unifiedOrder as UnifiedOrder, `(IOS in App환경) KICC를 통한 선불결제 과정에서 에러 발생. error: ${error}, iframeSrc: ${src}, unifiedOrder: ${this.docRefId}`, 'error');
      } else {
        this.utilService.toastError('결제를 진행할 수 없습니다. 고스트키친 고객센터(1522-6385)로 연락주시면 해결해 드리겠습니다. 불편을 끼쳐 대단히 죄송합니다.');
        // tslint:disable-next-line:max-line-length
        this.logService.logOrder(this.unifiedOrder as UnifiedOrder, `KICC를 통한 선불결제 과정에서 에러 발생. error: ${error}, iframeSrc: ${src}, unifiedOrder: ${this.docRefId}`, 'error');
      }
    }
  }

  /**
   * 지불수단(선불, 후불)에 따라 주문정보를 생성한다.
   */
  async createOrder(paymentMethod: 'advance' | 'deferred') {
    const docRefId = this.unifiedOrderService.getFingerDocId();
    if (!docRefId) {
      this.logService.logRoom(this.unifiedOrder.room, `주문 생성 실패: doc id 생성 실패`, 'error');
      this.utilService.toastError('주문 생성에 실패했습니다. 고스트키친 고객센터(1522-6385)로 연락주시면 해결해 드리겠습니다. 불편을 끼쳐 대단히 죄송합니다.');
      return false;
    }

    // 주문번호는 fingerId가 아닌 docRefId를 사용한다
    this.unifiedOrder.orderNo = docRefId;
    // 2021-06-08 배포 첫날 에러를 경험해서 일단 functions에 맡긴다.
    // tslint:disable-next-line:max-line-length
    // this.unifiedOrder.simpleNo = `${this.room.siteNo}-${String(await this.unifiedOrderService.getSimpleNo(this.unifiedOrder)).padStart(4, '0')}`;
    this.unifiedOrder.foods = this.unifiedOrderFoods;
    this.unifiedOrder.orderAmount = this.unifiedOrderFoods.reduce((sum, food) => sum + food.foodOrdPrice, 0);
    this.unifiedOrder.orderDate = fecha.format(new Date(), 'YYYY-MM-DDTHH:mm:ss+0900');

    if (paymentMethod === 'advance') {
      this.unifiedOrder.paymentMethod = '선불';

      let spProductNm = this.unifiedOrder.foods[0].mergedName;
      if (this.unifiedOrder.foods.length > 1) {
        spProductNm += `외 ${this.unifiedOrder.foods.length - 1}개`;
      }
      this.kiccOrder.sp_order_no = this.unifiedOrder.orderNo;
      this.kiccOrder.sp_user_phone1 = this.unifiedOrder.userTel;
      this.kiccOrder.sp_user_addr = this.unifiedOrder.address_detail;
      this.kiccOrder.sp_mall_nm = this.unifiedOrder.shopName;
      this.kiccOrder.sp_pay_type = this.spPayType;
      this.kiccOrder.sp_product_nm = spProductNm;
      this.kiccOrder.sp_product_amt = String(this.unifiedOrder.orderAmount);
    }

    if (paymentMethod === 'deferred') {
      this.unifiedOrder.paymentMethod = this.spPayType === '01' ? '후불카드' : '후불현금';
    }

    this.docRefId = docRefId;
    try {
      // 3. 주문내용(unifiedOrder) 기록
      const ret = await this.unifiedOrderService.createFingerOrder(this.unifiedOrder, this.docRefId);

      if (ret !== 'OK') {
        this.logService.logRoom(this.unifiedOrder.room, `주문 생성 실패: ${ret}`, 'error');
        this.utilService.toastError('주문 생성에 실패했습니다. 고스트키친 고객센터(1522-6385)로 연락주시면 해결해 드리겠습니다. 불편을 끼쳐 대단히 죄송합니다.');
      } else {
        this.unifiedOrder._id = `finger-${docRefId}`;
        this.logService.logOrder(this.unifiedOrder as UnifiedOrder, `'결제하기' 버튼을 눌러서 결제 대기(STAGING) 주문을 생성했습니다.`);
      }
    } catch (err) {
      this.logService.logRoom(this.unifiedOrder.room, `알 수 없는 이유로 주문 생성 실패: ${this.docRefId}/${err}`, 'error');
      this.utilService.toastError('주문 생성에 실패했습니다. 고스트키친 고객센터(1522-6385)로 연락주시면 해결해 드리겠습니다. 불편을 끼쳐 대단히 죄송합니다.');
      return false;
    }

    return true;
  }

  async finishOrder() {
    try {
      // [주문상태 변경] STAGING -> NEW
      await this.unifiedOrderService.mergeOrder({
        _id: this.unifiedOrder._id,
        orderStatusCode: UnifiedOrderStatusCode.NEW,
        contextStatusCode: UnifiedOrderContextStatusCode.NEW,
        // 결제 완료되어 실제 유효한 주문인 '신규주문'상태가 됐으니 orderDate를 갱신한다.
        orderDate: fecha.format(new Date(), 'YYYY-MM-DDTHH:mm:ss+0900')
      });
      this.logService.logOrder(this.unifiedOrder as UnifiedOrder, `Easypay를 통한 선불 결제가 완료되어 신규(NEW) 주문으로 상태를 변경했습니다.`);

      // 정상처리시 쇼핑카트를 비워준다.
      this.shoppingCartService.emptyCart();
    } catch (error) {
      // 주문 결제가 완료되었으나 상태변경에 실패한 경우
      this.logService.logOrder(this.unifiedOrder as UnifiedOrder, `주문 상태 변경 실패(STAGING -> NEW). ${this.docRefId}/${error}`, 'error');
      this.utilService.toastError('주문 생성에 실패했습니다. 고스트키친 고객센터(1522-6385)로 연락주시면 해결해 드리겠습니다. 불편을 끼쳐 대단히 죄송합니다.');
    }
  }


  /******************************************************************
   * [전화번호 문자인증]
   * 1. sendAuthSMS()     인증 문자 발송
   * 2. confirmSMSCode()  인증 문자 확인
   ******************************************************************/
  async sendAuthSMS() {
    const userTel = this.orderForm.get('userTel').value.replace(/-/g, '');

    try {
      await this.loadingService.presentLoading();
      this.gtagService.page('/req-auth');
      const response = await getRequestAuth(userTel);

      const { sessionId } = await response.json() as { sessionId: string };
      this.sessionId = sessionId;
      this.logService.info(`문자전송 성공 ${userTel}`);
    } catch (error) {
      await this.utilService.toastError('인증 문자 발송에 실패했습니다. 잠시후 다시 시도해주세요.');
      this.logService.error(`문자전송 실패 ${userTel} :; ${error}`);
    } finally {
      this.loadingService.dismissLoading();
    }
  }

  async confirmSMSCode() {
    const authCode = this.orderForm.get('authCode').value;
    const { userTel } = this.unifiedOrder;

    try {
      await this.loadingService.presentLoading();
      this.gtagService.page('/confirm-auth');
      const response = await getConfirmAuth(this.sessionId, authCode);

      const { result, reason, expires } = await response.json() as { result: string, reason: string | null, expires: string };
      // Safari는 +0900 형태에 대해서 에러를 리턴하기 때문에 parse후에 format을 한다.
      this.expires = fecha.format(fecha.parse(expires, 'YYYY-MM-DDTHH:mm:ssZZ'), 'YYYY-MM-DDTHH:mm:ss+09:00');
      this.confirmAuth = (result === 'success');

      if (reason !== null) {
        const msg = (reason === 'authCode mismatch') ? '잘못된 인증문자입니다.<br>다시 입력해주세요.' : reason;
        this.utilService.toastInfo(msg, undefined, 3000);
        this.logService.info(`문자인증 실패 ${userTel} : ${reason}`);
      } else {
        this.authCode = authCode;
        this.logService.info(`문자인증 성공 ${userTel}`);
      }

    } catch (error) {
      this.logService.error(`문자인증 실패 ${userTel} : ${error}`);
      this.utilService.toastError(`문자 인증에 실패하셨습니다. ${error}`);
    } finally {
      this.loadingService.dismissLoading();
    }
  }

  /******************************************************************
   * [주소검색]
   ******************************************************************/
  updateAddress(response: AugmentedAddress) {
    this.initModel();

    this.unifiedOrder = {
      ...this.unifiedOrder, ... {
        address_key: response.key,
        address_dongH: response.dongH,
        address_road: response.road,
        address_building_name: response.building_name,
        address_location: response.location,
        address_sido: response.sido,
        address_sigungu: response.sigungu,
        address_dong: response.dong,
        address_jibun: response.jibun,
        vroong: response.vroong,
      }
    };
  }

  async presentAddressModal() {
    try {
      this.gtagService.page('/search-address');
      await this.modalService.presentAddressModal((response) => this.updateAddress(response));
    } catch (error) {
      this.logService.error(`주소 찾기 실패 : ${error}`);
    }
  }

  checkDisposableItem(event: any) {
    this.noDisposableItem = event.target.checked;
  }
  /******************************************************************
   * [LocalStorage]
   ******************************************************************/
  // onCheckboxChange(event) {
  //   this.useLocalStorage = event.target.checked;
  // }

  saveLocalUserState() {
    const unifiedOrder = this.unifiedOrder;
    try {
      this.localStorageService.setItem('userTel', this.unifiedOrder.userTel);
      this.localStorageService.setItem('expires', this.expires);
      // 새로운 세션과 코드가 있는 경우만 최신화 한다.
      if (this.sessionId && this.authCode) {
        this.localStorageService.setItem('authSMS', {
          sessionId: this.sessionId,
          authCode: this.authCode
        });
      }

      // 배달고객인 경우 주소 저장
      if (this.deliveryType === 'DELIVERY') {
        this.localStorageService.setItem('userAddress', {
          address_key: unifiedOrder.address_key,
          address_dongH: unifiedOrder.address_dongH,
          address_road: unifiedOrder.address_road,
          address_building_name: unifiedOrder.address_building_name,
          address_location: unifiedOrder.address_location,
          address_sido: unifiedOrder.address_sido,
          address_sigungu: unifiedOrder.address_sigungu,
          address_dong: unifiedOrder.address_dong,
          address_jibun: unifiedOrder.address_jibun,
          address_detail: this.unifiedOrder.address_detail,
          vroong: unifiedOrder.vroong,
        });
      }
    } catch (error) {
      this.logService.error(`saveLocalUserState : ${error}`);
      this.utilService.toastError(`주문정보 저장 실패 : ${error}`);
    }

    this.orderForm.get('userTel').disable();
    this.orderForm.get('address_detail').disable();
  }

  async loadLocalUserState() {
    await this.loadingService.presentLoading();
    // 1. 저장된 전화번호 유무 확인
    if (!this.localStorageService.isExist('userTel')) {
      return this.loadingService.dismissLoading();
    }

    try {
      const userTel = this.localStorageService.getValue('userTel') as string;
      // 저장된 전화번호의 형식을 체크한다.
      const normalizedTel = normalizingTel(userTel);
      const match = normalizedTel.match(/^(0[157]0[1-9]?-[1-9][0-9]{3,4}-[0-9]{4}|02-[2-9][0-9]{2,3}-[0-9]{4})$/);
      if (match === null) {
        this.localStorageService.removeItem('userTel');
        throw Error('LocalStorage에 잘못된 형식의 전화번호가 저장되어있습니다.');
      }
      this.orderForm.get('userTel').setValue(userTel);

      // 2. 인증정보 확인
      if (userTel && this.localStorageService.isExist('expires')) {
        const localExpires = this.localStorageService.getValue('expires') as string;
        const localAuthSMS = this.localStorageService.getValue('authSMS') as { sessionId: string, authCode: string };

        // Safari는 +0900 형태에 대해서 에러를 리턴하기 때문에 parse후에 format을 한다.
        this.expires = fecha.format(fecha.parse(localExpires, 'YYYY-MM-DDTHH:mm:ssZZ'), 'YYYY-MM-DDTHH:mm:ss+09:00');

        const now = new Date();
        const expiredDate = new Date(localExpires);
        const isExpried = now.getTime() > expiredDate.getTime();
        // 3. 인증 기간 만료 여부 확인
        if (!isExpried) {
          // 4. 저장된 인증 정보로 재인증 및 만료기간 연장(refresh)
          if (localAuthSMS && localAuthSMS.sessionId && localAuthSMS.authCode) {
            const response = await getConfirmAuth(localAuthSMS.sessionId, localAuthSMS.authCode);
            const { result, reason, expires } = await response.json() as { result: string, reason: string | null, expires: string };

            this.confirmAuth = (result === 'success');
            if (!this.confirmAuth) {
              this.logService.info(`재인증 실패 sessionId: ${localAuthSMS.sessionId}, authCode: ${localAuthSMS.authCode} : ${reason}`);
              this.localStorageService.removeItem('expires');
              this.localStorageService.removeItem('authSMS');
            } else {
              this.expires = fecha.format(fecha.parse(expires, 'YYYY-MM-DDTHH:mm:ssZZ'), 'YYYY-MM-DDTHH:mm:ss+09:00');
              this.localStorageService.setItem('expires', this.expires);
            }
          }
        } else {
          this.localStorageService.removeItem('expires');
          this.localStorageService.removeItem('authSMS');
        }
      }

      // 배달 주문이며 저장된 주소가 있는가
      if (this.deliveryType === 'DELIVERY' && this.localStorageService.isExist('userAddress')) {
        const userAddress = this.localStorageService.getValue('userAddress') as UserAddress;
        this.orderForm.get('address_detail').setValue(userAddress.address_detail);
        this.orderForm.get('address_detail').disable();
        this.unifiedOrder = { ...this.unifiedOrder, ...userAddress };
      }

      this.userStateLoaded = true;
    } catch (error) {
      this.logService.info(`loadLocalUserState : ${error}`);
    }
    this.loadingService.dismissLoading();
  }

  changeUserState() {
    this.confirmAuth = false;
    this.userStateLoaded = false;
    this.orderForm.get('address_detail').enable();
  }

  /******************************************************************
   * [Routing]
   ******************************************************************/
  goHome() {
    this.router.navigate([this.currentSite], { replaceUrl: true });
  }


  /******************************************************************
   * [Observing]
   ******************************************************************/
  private observe() {
    const shopNo = this.shoppingCartService.shopNo;
    const unifiedMenu = this.unifiedMenuService.latestUnifiedMenuForSiteSubject;
    const roomDocs = this.roomService.latestSubject;

    // tslint:disable-next-line:max-line-length
    this.combineLatestSubscription = combineLatest([unifiedMenu, roomDocs]).subscribe(([unifiedMenu0, roomDocs0]) => {
      const shop = unifiedMenu0.find(menu => menu.shopNo === shopNo);
      const roomDoc = roomDocs0[shop.room];
      const isLive = (roomDoc?.enableFingerFace && roomDoc.live && !roomDoc.virtual);

      /** 2021.01.07 배민 운영 상태를 무시하는 '손가락 강제 오픈' 추가 */
      const orderAvailable = roomDoc.forceFingerOpen === true ? 'Y' : shop.baeminShopInfo.Ord_Avail_Yn;
      this.isOrderAvail = isLive ? orderAvailable : 'N';

      this.room = roomDoc;
    });
  }

  private observeShoppingCart() {
    this.shoppingCartsubscription = this.shoppingCartService.latestUnifiedOrderFoodsSubject.subscribe(unifiedOrderFoods => {
      this.unifiedOrderFoods = unifiedOrderFoods;

      this.totalAmount = this.unifiedOrderFoods.reduce((sum, food) => sum + food.foodOrdPrice, 0);
      this.totalQty = this.unifiedOrderFoods.reduce((sum, food) => sum + food.foodQty, 0);
    });
  }

  private isIOSInAppBrowser() {
    const { userAgent } = navigator;
    // tslint:disable-next-line: max-line-length
    if (!/mobile/i.test(userAgent) || !/inapp|KAKAOTALK|NAVER|Line\/|FB_IAB\/FB4A|FBAN\/FBIOS|Instagram|DaumDevice\/mobile|SamsungBrowser\/[^1]/i.test(userAgent)) {
      return false;
    }

    return /Mac/i.test(userAgent);
  }

  private getShopDetailFor(shopNo: string) {
    const shopDetail = this.shoppingCartService.shopDetail;
    if (shopDetail === undefined) {
      this.alertNoticeService.noticeAlert('업소 정보가 없습니다. 개발자에게 문의해주세요.');
      this.logService.error(`getShopDetailFor() - shopNo : ${shopNo}`);
      this.shoppingCartService.emptyCart();
      this.goHome();
      return;
    }

    const { organization, site, room, shopName } = shopDetail;
    return { organization, site, room, shopName };
  }
}
