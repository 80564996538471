/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { environment } from '../../../environments/environment';

const urlPrefix = `//dapi.kakao.com/v2/local/search/keyword.json`;
/**
 * Kakao 키워드로 주소 검색
 * : https://developers.kakao.com/docs/restapi/local
 */
export async function kakao_keyword_address_search(keyword: string, page?: number) {
  const requestInit: RequestInit = {
    method: 'GET',
    cache: 'no-cache',
    headers: {
      Authorization: `KakaoAK ${environment.kakaoAPIKey}`
    }
  };

  return fetch(`${urlPrefix}?query=${keyword}${page ? `&page=${page}` : '' }`, requestInit);
}
