/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import fecha from 'fecha';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private toastController: ToastController
  ) { }

  async toastInfo(message: string, header?: string, duration?: number) {
    const now = fecha.format(new Date(), `HH:mm:ss`);
    const toast = await this.toastController.create({
      position: 'top',
      color: 'light',
      header,
      message: `${message}`,
      // message: `[${now}] ${message}`,
      duration: duration ? duration : 10000
    });
    toast.present();
  }

  async toastWarning(message: string, header?: string, duration?: number) {
    const now = fecha.format(new Date(), `HH:mm:ss`);
    const toast = await this.toastController.create({
      position: 'top',
      color: 'warning',
      header,
      message: `[${now}] ${message}`,
      duration: duration ? duration : 10000
    });
    toast.present();
  }

  async toastError(message: string, header?: string, duration?: number) {
    const now = fecha.format(new Date(), `HH:mm:ss`);
    const toast = await this.toastController.create({
      position: 'top',
      color: 'danger',
      header,
      message: `[${now}] ${message}`,
      duration: duration ? duration : 60000
    });
    toast.present();
  }

  async toastCatch(error: Error | string, reason?: string) {
    const now = fecha.format(new Date(), `HH:mm:ss`);
    const msg = `${reason ? reason + ': ' : ''}${error}`;
    console.error(msg, error);

    const toast = await this.toastController.create({
      position: 'top',
      color: 'danger',
      header: '예외 발생',
      message: `[${now}] ${msg}`,
      duration: 30000
    });
    toast.present();
  }
}
