/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { AugmentedAddress } from '../../schema/1/schema-common';

import { CategoryModalComponent } from './category-modal/category-modal.component';
import { AddressSearchModalComponent } from './address-search-modal/address-search-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private modalController: ModalController
  ) { }

  async presentAddressModal(getAddress?: (response: AugmentedAddress) => void) {
    const modal = await this.modalController.create({
      component: AddressSearchModalComponent,
      componentProps: { getAddress }
    });
    return await modal.present();
  }

  async presentCategoryModal(allCategories: string[], categories: string[], setCategories: (categories: string[]) => void) {
    const modal = await this.modalController.create({
      component: CategoryModalComponent,
      componentProps: { allCategories, categories, setCategories }
    });
    return await modal.present();
  }
}
