/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PagesModule } from './pages/pages.module';
import { SharedModule } from './shared/shared.module';
import { FormModule } from './form/form.module';
import { AuthModule } from './auth/auth.module';
import { CustomLoadingComponent } from './shared/custom-loading/custom-loading.component';

@NgModule({
    declarations: [
        AppComponent,
        CustomLoadingComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        IonicModule.forRoot({
            mode: 'ios'
        }),
        SharedModule,
        AuthModule,
        PagesModule,
        FormModule,
        AppRoutingModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
