/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
// tslint:disable: object-literal-key-quotes

export const roleMappings = {
  admin: '관리자',
  operator: '접수자',
  ceo: '사장님',
};

export const unifiedOrderStatusMappings = {
  0: '이상상태',
  10: '신규주문', // 주문완료
  15: '업소접수',
  17: '배차대기',
  20: '주문접수', // 접수완료 - SMS
  30: '조리시작',
  40: '조리완료', // 조리완료 - SMS
  50: '포장완료',
  60: '배송중',
  70: '배송완료', // 픽업
  80: '취소',
  90: '삭제',
  95: '결제대기'
};

export const unifiedOrderVendorMappings = {
  baemin:       '배민',
  foodfly:      '푸플',
  yogiyo:       '요기요',
  ubereats:     '우버이츠',
  ddingdong:    '띵동',
  coupangeats:  '쿠팡이츠',
  shuttle:      '셔틀',
  ghostkitchen: '고스트키친',
  other:        '기타',
};

export const unifiedOrderChannelMappings = {
  app:  '앱',
  tel:  '전화',
  face: '직접방문'
};

export const unifiedOrderDeliveryTypeMappings = {
  DELIVERY:  '배달',
  TAKEOUT:   '테이크아웃',
  FOODFLY:   '푸드플라이',
  BAERA:     '배라',
  DDINGDONG: '띵동',
  UBER:      '우버이츠',
  COUPANG:   '쿠팡이츠',
  SHUTTLE:   '셔틀',
  HERE:      '매장식사'
};

export const unifiedOrderCreatedByMappings = {
  manual: '직접입력',
  face: '고객입력'
};

export const baeminAppListPaymentMethodMappings = {
  1: '만나서현금',
  2: '만나서카드',
};

export const baeminAppPaymentGroupMappings = {
  1: '선불',
  2: '후불'
};

export const baeminAppDetailPaymentMethodMappings = {
  1: '카드',
  2: '휴대폰결제',
  5: '만나서현금',
  6: '만나서카드',
  12: '페이코',
  13: '배민페이 계좌이체',
  14: '배민페이',
  15: '토스',
  16: '네이버페이',
  17: '카카오페이',
  30: '네이버페이2', // T0G20000076D 16과 구별하기 위해서 네이버페이2라고 했다.
  99: 'OK캐시백' // 2019-07-19. 100%할인 받은 경우일 수도 있다.
};

export const baeminCeoStatusMappings = {
  OPENED:    '신규',
  ACCEPTED:  '주문접수',
  CLOSED:    '배송완료',
  CANCELLED: '취소'
};

export const baeminCeoCategoryCodeMappings = {
  1: '치킨',
  2: '중식',
  3: '피자',
  4: '족발,보쌈',
  5: '야식',
  6: '찜,탕',
  7: '패스트푸드',
  9: '도시락',
  10: '돈까스,회,일식',
  32: '한식',
  33: '분식',
  34: '카페,디저트'
};

export const baeminCeoPurchaseTypeMappings = {
  BARO: '선불',
  MEET: '후불'
};

export const vroongStatusMappings = {
  SUBMITTED: '배차대기',
  ASSIGNED:  '픽업중',
  PICKED_UP: '배송중',
  DELIVERED: '배송완료',
  CANCELED:  '취소',
};

export const vroongPayMethodMappings = {
  PREPAID: '선불',
  AT_DEST_CARD: '후불카드',
  AT_DEST_CASH: '후불현금'
};

export const foodflyAcceptStatusMappings = {
  0:  '신규',
  1:  '미접수', // 접수하지 않았는데 취소된 경우로 추정
  3:  '주문접수',
  5:  '조리완료',
  98: '취소',
  99: '주문거절'
};

export const foodflySendStatusMappings = {
  0: '미배차',
  1: '배차대기',
  2: '배차완료',
  3: '업장도착',
  4: '픽업',
  5: '배달완료',
  6: '취소'
};

export const coupangeatsOrderStatusMappings = {
  PAYMENT_APPROVED: '신규',
  ACCEPTED: '주문접수',
  PICKED_UP: '배송중',
  COMPLETED: '배송완료',
  CANCELLED: '취소'
};

export const coupangeatsDeliveryStatusMappings = {
  MERCHANT_ACCEPTED: '배차대기',
  COURIER_ACCEPTED: '픽업중',
  MERCHANT_READY: '조리완료',
  COURIER_PICKEDUP: '배송중',
  DELIVERED: '배송완료',
  CANCELLED: '취소'
};

export const combinenetStateCodeMappings = {
  1: '접수',
  2: '배차',
  3: '픽업',
  5: '배달완료',
  44: '취소'
};

export const combinenetPayMethodMappings = {
  0: '후불현금',
  1: '후불카드',
  2: '선불'
};

export const roomPrinterMappings = {
  samsung01: '삼성점 1호',
  samsung02: '삼성점 2호',
  samsung03: '삼성점 3호',
  samsung04: '삼성점 4호',
  samsung05: '삼성점 5호',
  samsung06: '삼성점 6호',
  samsung07: '삼성점 7호',
  samsung08: '삼성점 8호',
  samsung09: '삼성점 9호',
  samsung10: '삼성점 10호',
  samsung11: '삼성점 11호',
  samsung12: '삼성점 12호',
  samsung13: '삼성점 13호',
  samsung14: '삼성점 14호',
  samsung15: '삼성점 15호',
  samsungDesk: '삼성점 데스크',
  kangnam01: '강남역점 1호',
  kangnam02: '강남역점 2호',
  kangnam03: '강남역점 3호',
  kangnam04: '강남역점 4호',
  kangnam05: '강남역점 5호',
  kangnam06: '강남역점 6호',
  kangnam07: '강남역점 7호',
  kangnam08: '강남역점 8호',
  kangnam09: '강남역점 9호',
  kangnam10: '강남역점 10호',
  kangnam11: '강남역점 11호',
  kangnam12: '강남역점 12호',
  kangnam13: '강남역점 13호',
  kangnam14: '강남역점 14호',
  kangnam15: '강남역점 15호',
  kangnam16: '강남역점 16호',
  kangnam17: '강남역점 17호',
  kangnam18: '강남역점 18호',
  kangnam19: '강남역점 19호',
  kangnam20: '강남역점 20호',
  kangnam21: '강남역점 21호',
  kangnam22: '강남역점 22호',
  kangnam23: '강남역점 23호',
  kangnam24: '강남역점 24호',
  kangnam25: '강남역점 25호',
  kangnam26: '강남역점 26호',
  kangnam27: '강남역점 27호',
  kangnamDesk: '강남역점 데스크',
  NA: '??'
};

export const simpleNoticeMappings = {
  hide: '비활성화',
  show: '활성화',
  deleted: '삭제',
};

export const payTypeMap = {
  11: '신용카드',
  22: '가상계좌',
  31: '휴대폰',
  50: '선불결제',
  60: '간편결제',
  81: '배차인증'
};

export const kiccResponseMap = {
  '0000': '정상',
  '0200': '인증실패', // 금융결제원 류
  W001: '비정상 접속',
  W002: '결제를 취소하셨습니다.', // [USER] 사용자 소
  W101: '[MALL]가맹점ID가 비었습니다.',
  W102: '[MALL]가맹점명이 비었습니다.',
  W103: '[MALL]결제수단이 비었습니다.',
  W104: '[MALL]통화코드가 비었습니다.',
  W105: '[MALL]가맹점 주문번호가 비었습니다.',
  W106: '[MALL]고객휴대폰번호가 비었습니다.',
  W107: '[MALL]카드코드가 비었습니다.',
  W108: '[MALL]할부개월이 비었습니다.',
  W109: '[MALL]유효기간이 비었습니다.',
  W110: '[MALL]상품명이 비었습니다.',
  W111: '[MALL]상품금액이 비었습니다.',
  W112: '[MALL]포인트 설정값이 비었습니다.',
  W113: '[MALL]무이자 설정값이 비었습니다.',
  W114: '[MALL]가맹점 return url이 비었습니다.',
  W115: '[MALL]에스크로 구매자명이 비었습니다.',
  W116: '[MALL]에스크로 구매자휴대폰번호가 비었습니다.',
  W117: '[MALL]에스크로 구매자이메일이 비었습니다.',
  W118: '[MALL]에스크로 장바구니 총금액이 비었습니다.',
  W119: '[MALL]에스크로 장바구니 건수가 비었습니다.',
  W120: '[MALL]에스크로 장바구니 정보가 비었습니다.',
  W121: '[MALL]카드 Prefix 정보가 비었습니다.',
  W122: '[MALL]추적번호 정보가 비었습니다.',
  W123: '[MALL]카드번호가 비었습니다.',
  W140: '[MALL]상품금액이 숫자가 아닙니다.',
  W141: '[MALL]할부개월이 숫자가 아닙니다.',
  W142: '[MALL]에스크로 장바구니 총금액이 숫자가 아닙니다.',
  W143: '[MALL]에스크로 장바구니 건수 값이 숫자가 아닙니다.',
  W150: '[MALL]결제수단 값이 잘못되었습니다.',
  W151: '[MALL]서비스기간 값이 잘못되었습니다.',
  W152: '[MALL]입금만료날짜 값이 잘못되었습니다.',
  W153: '[MALL]입금만료시간 값이 잘못되었습니다.',
  W154: '[MALL]장바구니 정보 값이 잘못되었습니다.',
  W155: '[MALL]카드코드 값이 잘못되었습니다.',
  W156: '[MALL]유효기간 값이 잘못되었습니다.',
  W157: '[MALL]고객 휴대폰 값이 잘못되었습니다.',
  W158: '[MALL]할부개월 값이 잘못되었습니다. (금액 50000원 이상)',
  W159: '[MALL]국내카드를 이용하시고 잇습니다. 안심클릭이나 ISP로 재시도 해주시기 바랍니다.',
  W170: '[MALL]가맹점ID 길이가 잘못되었습니다. (길이:8)',
  W171: '[MALL]결제수단 길이가 잘못되었습니다. (길이:2)',
  W172: '[MALL]가맹점명 길이가 잘못되었습니다. (최소:2자 이상)',
  W200: '[KICC]DB처리 오류. 잠시후 재시도 요망',
  W201: '[KICC]DB조회 실패. 입력하신 데이터를 확인해 주시기 바랍니다.',
  W210: '[KICC]가맹점 정보가 없습니다.',
  W211: '[KICC]등록된 결제수단이 없습니다.',
  W212: '[KICC]등록된 계좌이체 서비스 정보가 없습니다.',
  W213: '[KICC]등록된 휴대폰 서비스 정보가 없습니다.',
  W214: '[KICC]등록된 선불카드 서비스 정보가 없습니다.',
  W215: '[KICC]등록된 페이핀 서비스 정보가 없습니다.',
  W216: '[KICC]페이핀 인증내역이 없습니다.',
  W217: '[KICC]쿠폰 정보가 없습니다.',
  W230: '[KICC]가맹점 Status가 정상이 아닙니다.',
  W231: '[KICC]가맹점 승인허가상태가 정상이 아닙니다.',
  W240: '[KICC]추적번호가 생성되지 않았습니다.',
  W250: '[KICC]요청정보를 저장하지 못했습니다. KICC로 문의바랍니다.',
  W251: '[KICC]인증정보를 저장하지 못했습니다. KICC로 문의바랍니다.',
  W252: '[KICC]인증정보를 저장하지 못했습니다. KICC로 문의바랍니다.',
  W253: '[KICC]결과코드를 저장하지 못했습니다. KICC로 문의바랍니다.',
  W254: '[KICC]휴대폰인증정보를 저장하지 못했습니다. KICC로 문의바랍니다.',
  W255: '[KICC]인증기관 거래번호를 저장하지 못했습니다. KICC로 문의바랍니다.',
  W256: '[KICC]쿠폰정보를 저장하지 못했습니다. KICC로 문의바랍니다.',
  W270: '[KICC]에스크로 가맹점이 아닙니다.',
  W301: '[ISP]안전결제 인증에 실패하였거나 취소를 하였습니다.',
  W302: '[ISP]가맹점에서 사용불가능한 카드입니다.',
  W303: '[ISP]VP에서 인증거래 요청에 대한 응답이 없습니다.',
  W304: '[ISP]VP로 인증거래 요청 실패 하였습니다.',
  W305: '[ISP]VP로 인증거래 요청 실패 하였습니다.',
  W311: '[V3D]요청파라메터 없음.',
  W312: '[V3D]가맹점에서 사용불가능한 카드입니다.',
  W313: '[V3D]해당 카드 미등록 가맹점입니다.',
  W314: '[V3D]하위 사업자번호가 없습니다.',
  W321: '[XMPI]XMPI가 정상적으로 설치되지 않았습니다.',
  W322: '[XMPI]안심클릭 대상 카드가 아닙니다.',
  W323: '[XMPI]암호화 오류발생',
  W324: '[XMPI]인증에 실패하였거나 취소를 하였습니다.',
  W325: '[XMPI]카드사에서 응답값을 주지 않았습니다.[r0=null]',
  W331: '[MPI]카드사에서 응답값을 주지 않았습니다.[pares=null]',
  W332: '[MPI]카드사에서 응답값을 주지 않았습니다.[md=null]',
  W341: '[SMPI]SMPI가 정상적으로 설치되지 않았습니다.',
  W342: '[SMPI]안심클릭 대상 카드가 아닙니다.',
  W343: '[SMPI]암호화 오류발생',
  W344: '[SMPI]인증에 실패하였거나 취소를 하였습니다.',
  W345: '[SMPI]카드사에서 응답값을 주지 않았습니다.[r0=null]',
  W351: '[OSMPI]카드사에서 응답값을 주지 않았습니다.[pares=null]',
  W352: '[OSMPI]카드사에서 응답값을 주지 않았습니다.[md=null]',
  W361: '[KMOTION]가맹점에서 사용불가능한 카드입니다.',
  W401: '[선불]선불카드 인증에 실패하였습니다.',
  W501: '[쿠폰]쿠폰 발행에 실패하였습니다.',
  W601: '[KICC]전화요망(02-3416-2900)',
  // KMOTION 오류
  K100: '[KMOTION]인증 취소를 하였습니다.',
  K101: '[KMOTION]거래번호가 일치하지 않습니다.',
  1001: '[KMOTION]팝업 차단 설정 해제 후 다시 결제를 해 주십시오.',
  2001: '[KMOTION]인증 데이터 처리에 실패 하였습니다. 다시 시도해 주십시오.',
  3001: '[KMOTION]거래키 발급에 실패하였습니다. 다시 시도해 주십시오.',
  3002: '[KMOTION]인증 데이터 처리에 실패하였습니다. 다시 시도해 주십시오.',
  9101: '[KMOTION]결제 코드 발급에 실패하였습니다. 다시 시도해 주십시오.',
  9102: '[KMOTION]결제 코드 발급에 실패하였습니다. 다시 시도해 주십시오.',
  9103: '[KMOTION]결제 코드 발급에 실패하였습니다. 다시 시도해 주십시오.',
  9104: '[KMOTION]결제 코드 발급에 실패하였습니다. 다시 시도해 주십시오.',
  9105: '[KMOTION]결제 코드 발급에 실패하였습니다. 다시 시도해 주십시오.',
  9106: '[KMOTION]결제 코드 발급에 실패하였습니다. 다시 시도해 주십시오.',
  9199: '[KMOTION]거래 코드 발급에 실패하였습니다. 다시 시도해 주십시오.',
  9201: '[KMOTION]거래키 요청에 실패하였습니다. 다시 시도해 주십시오.',
  9202: '[KMOTION]거래키 요청에 실패하였습니다. 다시 시도해 주십시오.',
  9203: '[KMOTION]거래키 요청에 실패하였습니다. 다시 시도해 주십시오.',
  9204: '[KMOTION]거래키 요청에 실패하였습니다. 다시 시도해 주십시오.',
  9205: '[KMOTION]거래키 요청에 실패하였습니다. 다시 시도해 주십시오.',
  9206: '[KMOTION]결제 인증 시간이 만료되었습니다. 다시 시도해 주십시오.',
  9207: '[KMOTION]이미 결제된 코드입니다. 다시 시도해 주십시오.',
  9299: '[KMOTION]거래키 요청에 실패하였습니다. 다시 시도해 주십시오.',
  9999: '[KMOTION]결제 도중 오류가 발생하였습니다. 다시 시도해 주십시오.',
  // 이지페이 Client 오류
  M101: '통신 초기화 오류',
  M102: 'PG 서버 접속 오류',
  M103: 'PG 서버 DATA 전송 오류',
  M104: 'PG 서버 DATA 수신 오류',
  M201: '요청 전문 생성 오류',
  M202: '요청 전문 암호화 오류',
  M203: '응답 전문 복호화 오류',
  M204: '전문 암복호화 정보 생성 오류',
  M205: '추적번호 없음',
  M206: '암호화 KEY정보 누락'
};

export const baeminCategoryColorMap = {
  패스트푸드: '#AE2424',
  한식: '#9C165B',
  '아시안·양식': '#d434d4',
  분식: '#7E57C2',
  '카페·디저트': '#3949AB',
  치킨: '#ed576b',
  중식: '#01897B',
  '찜·탕': '#215934',
  피자: '#A4C447',
  '돈까스·회·일식': '#CE9509',
  도시락: '#FF7000',
  '족발·보쌈': '#815018',
  야식: '#2B2B2B'
};

export const sortOptionMap = {
  shuffle: '무작위',
  'price-asc': '높은 가격순',
  'price-desc': '낮은 가격순',
};
