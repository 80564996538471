<div class="menu-option-container" [class.hide]="!showUp">
  <div class="option-box">
    <div class="menu-amount">
      전체: <span>{{ totalItemLength }}</span>
    </div>
    <div class="detail" [class.active]="showOptionPanel" (click)="optionPanel()">
      <span *ngIf="!showOptionPanel"><ion-icon name="search-outline"></ion-icon>검색 및 설정</span>
      <span *ngIf="showOptionPanel"><ion-icon name="close-outline"></ion-icon>닫기</span>
    </div>
  </div>

  <div [class.show]="showOptionPanel" [class.closed]="displayNone" class="detail-options">
    <div class="option-header">
      <button (click)="initFilter()">
        <div class="inner-box">
          <ion-icon name="refresh-outline"></ion-icon>초기화
        </div>
      </button>
      <span>검색 결과: {{ filteredItemLength }}</span>
    </div>
    <!-- 메뉴 이름 검색 -->
    <div class="search-option">검색</div>
    <ion-searchbar class="ion-no-padding ion-no-margin" 
        placeholder="메뉴명 입력 예)찌개" 
        debounce="700" 
        (ionChange)="setSearchText($event)" 
        [value]="searchKey" 
        (keydown.enter)="closeKeyboad($event)"></ion-searchbar>
    <!-- 카테고리 -->
    <div class="category-option" (click)="presentCategoryModal()">
      <span class="label">카테고리</span>
      <span class="option-value category">{{ summarizedCategories }}<ion-icon name="caret-down-outline"></ion-icon></span>
    </div>
    <!-- 가격 범위 -->
    <div class="price-option">
      <span class="label">가격범위</span>
      <div class="range-container">
        <ion-range class="ion-no-padding ion-no-margin" dual-knobs pin 
          #rangeRef
          [min]="0" [max]="maxPrice" [step]="1000"
          [value]="priceRange">
        </ion-range>
        <span class="option-value price">{{ displayPriceRange.lower | number }}~{{ displayPriceRange.upper | number }}원</span>
      </div>
    </div>
    <div class="grid-option-box">
      <div class="label left">열 개수</div>
      <div class="option-button">
        <button [class.active]="rowSize === 1" (click)="selectRowSize(1)">1개</button>
        <button [class.active]="rowSize === 2" (click)="selectRowSize(2)">2개</button>
        <button [class.active]="rowSize === 3" (click)="selectRowSize(3)">3개</button>
      </div>
    </div>
    <div class="sort-option-box">
      <div class="label left">정렬</div>
      <div class="option-button">
        <button (click)="selectSortOption('price-desc')" [class.active]="sortOption === 'price-desc'">낮은 가격순</button>
        <button (click)="selectSortOption('price-asc')" [class.active]="sortOption === 'price-asc'">높은 가격순</button>
        <button (click)="selectSortOption('shuffle')" [class.active]="sortOption === 'shuffle'">무작위</button>
      </div>
    </div>
  </div>
  <div [class.show]="showOptionPanel" (click)="optionPanel()" class="option-box-bg"></div>
  <div [class.show]="showOptionPanel" class="option-box-border"></div>

  <div (click)="optionPanel()"
    class="filter-list"
    [class.show]="!showOptionPanel && !isLoading" *ngIf="!isCategoryAll || (priceRange.lower !== 0) || (priceRange.upper !== maxPrice) || searchKey || sortOption !== 'shuffle'">
    <span *ngIf="sortOption !== 'shuffle'">{{ sortOptionKR }}</span>
    <span *ngIf="searchKey">{{ searchKey }}</span>
    <span *ngIf="(priceRange.lower !== 0) || (priceRange.upper !== maxPrice)">{{ priceRange.lower | number }}~{{ priceRange.upper | number }}원</span>
    <span *ngIf="!isCategoryAll">{{ summarizedCategories }}</span>
    <span class="filter-result">검색 결과: {{ filteredItemLength }}</span>
  </div>
</div>

<div class="grid-menu-container" [class.hide]="!showUp">
  <ion-item-group [class.loaded]="isLoading">
    <ion-grid>
      <ng-container *ngFor="let item of dummyGrid; let i = index">
        <ion-row>
          <ion-col *ngFor="let col of item; let j = index" [size]="12/rowSize">
            <div class="image-mask loading"></div>
          </ion-col>
        </ion-row>
      </ng-container>
    </ion-grid>
  </ion-item-group>

  <ion-item-group [class.loaded]="!isLoading" class="menu-group" [class.addPadding]="!(!isCategoryAll || (priceRange.lower !== 0) || (priceRange.upper !== maxPrice) || searchKey) || showOptionPanel">
    <ion-grid>
      <!-- 이미지가 있는 메뉴 -->
      <ng-container *ngFor="let item of imageMenuViewModel; let i = index">
        <ion-row [class]="'row-size-' + rowSize">
          <ion-col *ngFor="let col of item.row; let j = index"
            [size]="12/rowSize" 
            (click)="goShop(col.shopNo, col.foodNo)">
            <div class="image-mask">
              <img [src]="col.imageUrl" loading="lazy">
            </div>
            <span class="title">
              <span class="name">{{ col.foodName }}</span>
              <span class="room">{{ col.roomNo }}</span>
            </span>
            <span class="sub-title">
              <span class="price">{{ col.price | number }}원</span>
              <span *ngIf="col.category" class="category" [style.color]="col.color">{{ col.category }}</span>
            </span>
          </ion-col>
        </ion-row>
      </ng-container>
      <!-- 이미지가 없는 메뉴 -->
      <ng-container *ngFor="let item of textMenuViewModel; let i = index">
        <ion-row [class]="'text-only ' + 'row-size-' + rowSize">
          <ion-col *ngFor="let col of item.row; let j = index"
            [size]="12/rowSize" 
            (click)="goShop(col.shopNo, col.foodNo)">
            <div class="empty-box"></div>
            <span class="title">
              <span class="name">{{ col.foodName }}</span>
              <span class="room">{{ col.roomNo }}</span>
            </span>
            <span class="sub-title">
              <span class="price">{{ col.price | number }}원</span>
              <span *ngIf="col.category" class="category" [style.color]="col.color">{{ col.category }}</span>
            </span>
          </ion-col>
        </ion-row>
      </ng-container>
    </ion-grid>

    <ion-infinite-scroll threshold="100%" (ionInfinite)="loadData($event)">
      <ion-infinite-scroll-content loading-spinner="bubbles">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>

    <div class="empty-result" *ngIf="imageMenuModel.length === 0 && textMenuModel.length === 0">
      검색된 메뉴가 없습니다.
    </div>
  </ion-item-group>
</div>

